import { gql } from '@apollo/client';

export const VULNERABILITY = gql`
  query vulnerability($args: VulnerabilityInput!) {
    vulnerability(args: $args) {
      id
      createdDatetime
      updatedDatetime
      processedDatetime
      description
      problemTypes {
        value
        name
        url
        __typename
      }
      exploit {
        publishDatetime
        exploitDatetime
        recentExploitDatetime
        pocDatetime
        weaponizeDatetime
        threatActorDatetime
        recentThreatActorDatetime
        ransomwareDatetime
        recentRansomwareDatetime
        botnetDatetime
        recentBotnetDatetime
        found
        maturity
        inTheWild
        inThreatActors
        inRansomware
        inBotnets
        isTrendingGithub
        knownAttacks
        inKnownExploitedVulnerabilities
        epss {
          score
          percentile
        }
        prevalence {
          exploit
          threatActors
          botnets
          knownAttacks
        }
        references {
          exploits {
            url
            name
            refsource
            dateAdded
            exploitMaturity
            exploitAvailability
          }
          botnets {
            botnetName
            dateAdded
          }
          threatActors {
            name
            aliases
            url
          }
          ransomware {
            families
            references {
              date
              url
            }
          }
          knownExploitedVulnerabilities {
            action
            dateAdded
            dueDate
            name
          }
          knownAttacks {
            name
            displayName
            associatedVulnerabilitiesList
          }
        }
      }
      references {
        url
        name
        refsource
      }
      impact {
        base {
          vectorString
          attackVector
          attackComplexity
          privilegesRequired
          userInteraction
          scope
          confidentialityImpact
          integrityImpact
          availabilityImpact
          baseScore
          baseSeverity
          impactScore
          exploitabilityScore
        }
      }
      impactV2 {
        base {
          version
          vectorString
          accessVector
          accessComplexity
          authentication
          integrityImpact
          confidentialityImpact
          availabilityImpact
          baseScore
          impactScore
          exploitabilityScore
          severity
        }
      }
    }
  }
`;

export const VULNERABILITIES = gql`
  query vulnerabilities($args: PaginatedVulnerabilitiesInput!) {
    vulnerabilities(args: $args) {
      pageInfo {
        totalCount
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          cve
          componentId
          name
          version
          vendor
          maturity
          filePath
          severity
          attackVector
          attackComplexity
          inKnownExploitedVulnerabilities
          epssScore
          epssPercentile
          correlations {
            assetName
            assetId
          }
          currentRemediation {
            assetId
            vulnerabilityId
            remediationId
            componentId
            justification
            response
            description
            status
            createdAt
            author
          }
        }
      }
    }
  }
`;

export const VULNERABILITIES_EXTERNAL_FILTER = gql`
  query vulnerabilityExternalFilters($args: VulnerabilityExternalFiltersInput!) {
    vulnerabilityExternalFilters(args: $args) {
      inBotnets
      inThreatActors
      inRansomware
      inKnownAttacks
      exploits
      low
      medium
      high
      critical
    }
  }
`;

export const GET_ALL_VULN_IDS = gql`
  query vulnerabilities($args: PaginatedVulnerabilitiesInput!) {
    vulnerabilities(args: $args) {
      edges {
        node {
          cve
          componentId
          name
        }
      }
    }
  }
`;

export const REMEDIATE_ALL_ASSET_VULNERABILITIES = gql`
  mutation remediateAllAssetVulnerabilities($args: CreateAllAssetVulnerabilitiesRemediationInput!) {
    remediateAllAssetVulnerabilities(args: $args) {
      err
    }
  }
`;

export const REMEDIATE_ASSET_VULNERABILITIES = gql`
  mutation remediateAssetVulnerabilities($args: CreateAssetVulnerabilityRemediationsInput!) {
    remediateAssetVulnerabilities(args: $args) {
      cve
      componentId
      name
      version
      vendor
      maturity
      filePath
      severity
      attackVector
      attackComplexity
      inKnownExploitedVulnerabilities
      correlations {
        componentName
        componentId
      }
      currentRemediation {
        assetId
        vulnerabilityId
        remediationId
        componentId
        justification
        response
        description
        status
        createdAt
        author
      }
    }
  }
`;
