import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import NRRemediationStepOne from '../NRRemediationStepOne';
import NRRemediationStepTwo, { SHOW_JUSTIFICATION_STATUS, SHOW_RESPONSE_STATUS } from '../NRRemediationStepTwo';
import NRRemediationStepThree from '../NRRemediationStepThree';
import { useContext, useEffect, useState } from 'react';
import { RemediationContext } from '../NRRemediationContext';
import { REMEDIATION_CONSTANTS } from '../NRRemediation.constants';
import StepperIndicator from './StepperIndicator';
import useNRMutation from '../../../graphql/UseNRMutation';
import { REMEDIATE_ALL_ASSET_VULNERABILITIES, REMEDIATE_ASSET_VULNERABILITIES, VULNERABILITIES } from '../../../graphql/queries/vulnerability';
import { useParams } from 'react-router-dom';
import { SBOMContext } from '../../../pages/NRSBOMTable/NRSBOMDataShare';
import NRButtonProgress from '../../NRButtonProgress/NRButtonProgress';
import { useSnackbar } from 'notistack';
import Divider from '@mui/material/Divider';
import NRRemediationAlert from '../NRRemediationAlert/NRRemediationAlert';
import groupBy from 'lodash.groupby';
import NRVulnerabilityDetails from '../../NRVulnerabilitiesTable/NRVulnerabilityDetails/NRVulnerabilityDetails';
import { FiltersContext } from '../../../pages/NRReportTabs/NRReportFilterContextWrapper';
import { ComponentContext } from '../../../ContextWrappers/NRComponentContextWrapper';
import { GET_LATEST_SNAPSHOT } from '../../../graphql/queries/asset';
import { GridContext } from '../../NRReactGrid/NRReactGridContext';
import { isEmpty } from '../../../utils/utils';

const steps = ['Status', 'Details', 'Export'];

function NRRemediationContent({
  inDrawer = false,
  cve,
  dependencyComponentId,
  onSubmitExtraActions = () => {},
  onResponseExtraActions = () => {},
  onCancelExtraActions = () => {}
}) {
  const { componentId } = useParams();
  const { setOpenDownloadSBOM } = useContext(SBOMContext);
  const { CVEs, setOpenRemediationModal, lastQueryVariables, remediateAllVulnerabilitiesFilter } = useContext(RemediationContext);
  const { setShouldClearSelection } = useContext(GridContext);
  const { setDrawerContent, drawerContent } = useContext(FiltersContext);
  const { asset, setAsset } = useContext(ComponentContext);

  const [showAlert, setShowAlert] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [remediateAssetVulnerabilities, result, loading] = useNRMutation(REMEDIATE_ASSET_VULNERABILITIES, null, null, null, {
    refetchQueries: [
      { query: GET_LATEST_SNAPSHOT, variables: { args: { assetId: componentId } }, context: { version: 'v3' } },
      { query: VULNERABILITIES, variables: { ...lastQueryVariables }, context: { version: 'v3' } }
    ],
    awaitRefetchQueries: true,
    onCompleted: (result, refetch) => {
      const remediationResult = result.remediateAssetVulnerabilities;
      if (dependencyComponentId) {
        const newDrawerContent = [...drawerContent];
        const node = remediationResult.find(e => {
          return e.cve === cve && e.componentId === dependencyComponentId;
        });
        newDrawerContent[drawerContent?.length - 1] = <NRVulnerabilityDetails row={node} />;
        setDrawerContent(newDrawerContent);
      }
      if (!asset.hasRemediation) {
        setAsset({ ...asset, hasRemediation: true });
      }
    }
  });

  const [remediateAllVulnerabilities, remediateAllVulnerabilitiesResult, remediateAllVulnerabilitiesLoading] = useNRMutation(
    REMEDIATE_ALL_ASSET_VULNERABILITIES,
    'remediateAllAssetVulnerabilities',
    null,
    null,
    {
      refetchQueries: [
        { query: GET_LATEST_SNAPSHOT, variables: { args: { assetId: componentId } }, context: { version: 'v3' } },
        { query: VULNERABILITIES, variables: { ...lastQueryVariables }, context: { version: 'v3' } }
      ],
      awaitRefetchQueries: true,
      onCompleted: (result, refetch) => {
        if (!asset.hasRemediation) {
          setAsset({ ...asset, hasRemediation: true });
        }
      }
    }
  );

  const emptyForm = {
    status: REMEDIATION_CONSTANTS.STATUS.VALUES.UNSPECIFIED,
    justification: null,
    response: null,
    description: '',
    downloadSBOM: false
  };
  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: emptyForm
  });
  const { handleSubmit, watch, setValue } = methods;
  const form = watch();

  const onSubmitHandler = values => {
    onSubmitExtraActions(form);
    if (isEmpty(remediateAllVulnerabilitiesFilter)) {
      remediateAssetVulnerabilities({
        context: { version: 'v3' },
        variables: {
          args: {
            assetId: componentId,
            description: values.description,
            remediationIds: CVEs?.map(cve => ({
              vulnerabilityId: cve.cve,
              componentId: cve.componentId
            })),
            justification: SHOW_JUSTIFICATION_STATUS.includes(form.status) ? values.justification : null,
            response: SHOW_RESPONSE_STATUS.includes(form.status) ? values.response : null,
            status: values.status
          }
        }
      });
    } else {
      remediateAllVulnerabilities({
        context: { version: 'v3' },
        variables: {
          args: {
            assetId: remediateAllVulnerabilitiesFilter?.assetId,
            vulnerabilityFilter: remediateAllVulnerabilitiesFilter?.filter,
            description: values.description,
            justification: SHOW_JUSTIFICATION_STATUS.includes(form.status) ? values.justification : null,
            response: SHOW_RESPONSE_STATUS.includes(form.status) ? values.response : null,
            status: values.status
          }
        }
      });
    }
  };

  useEffect(() => {
    if (result || remediateAllVulnerabilitiesResult?.err === null) {
      enqueueSnackbar(REMEDIATION_CONSTANTS.SUCCESS.MESSAGE.replace(`${0}`, CVEs.length), { variant: 'success' });
      setOpenRemediationModal(false);
      if (form.downloadSBOM) {
        setOpenDownloadSBOM(true);
      }
      onResponseExtraActions(form);
      setShouldClearSelection(true);
    }
  }, [result, remediateAllVulnerabilitiesResult]);

  useEffect(() => {
    if (CVEs) {
      const statusKeys = Object.keys(groupBy(CVEs, 'currentRemediation.status'));
      const justificationKeys = Object.keys(groupBy(CVEs, 'currentRemediation.justification'));
      const responseKeys = Object.keys(groupBy(CVEs, 'currentRemediation.response'));
      const descriptionKeys = Object.keys(groupBy(CVEs, 'currentRemediation.description'));
      if (statusKeys.length === 1 && justificationKeys.length === 1 && descriptionKeys.length === 1) {
        if (descriptionKeys[0] !== 'null' && descriptionKeys[0] !== 'undefined') setValue('description', descriptionKeys[0]);
        if (justificationKeys[0] !== 'null' && justificationKeys[0] !== 'undefined') setValue('justification', justificationKeys[0]);
        if (responseKeys[0] !== 'null' && responseKeys[0] !== 'undefined') setValue('response', responseKeys[0]);
        if (statusKeys[0] !== 'undefined' && statusKeys[0] !== 'null') setValue('status', statusKeys[0]);
      } else {
        setShowAlert(true);
      }
    }
  }, [CVEs]);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCancel = () => {
    setOpenRemediationModal(false);
    onCancelExtraActions();
  };

  return (
    <Box {...(inDrawer && { height: '100%' })}>
      <FormProvider {...methods}>
        <form {...(inDrawer && { style: { height: '100%' } })} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitHandler)}>
          <Box
            width={'100%'}
            sx={{
              ...(inDrawer && {
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              })
            }}
          >
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel icon={<StepperIndicator number={index} completed={activeStep > index} active={activeStep === index} />}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {showAlert && <NRRemediationAlert />}
              {activeStep === 0 && <NRRemediationStepOne />}
              {activeStep === 1 && <NRRemediationStepTwo />}
              {activeStep === 2 && <NRRemediationStepThree />}
              {inDrawer && <Divider sx={{ marginTop: 'auto', marginLeft: '-24px', marginRight: '-24px' }} />}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button variant={'outlined'} color={'secondary'} onClick={() => handleCancel()} sx={{ mr: 1 }}>
                  Cancel
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant={'outlined'} color={'secondary'} disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                {activeStep !== steps.length - 1 && (
                  <Button onClick={handleNext} variant={'contained'} color={'secondary'}>
                    Next
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <NRButtonProgress variant="contained" color="secondary" type={'submit'} disabled={loading} isLoading={loading} label={'Finish'} />
                )}
              </Box>
            </React.Fragment>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
}

export default NRRemediationContent;
