import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink, defaultDataIdFromObject, HttpLink } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { GRAPHQL_URL } from './helpers/environment';
import { getToken } from './services/auth';
import { CONSTANTS } from './constants/constants';
import { extractPartFromPathname } from './utils/utils';

export let client;

const ApolloProviderWithAuth0 = ({ children }) => {
  const { getAccessTokenSilently, user } = useAuth0();

  const authLink = setContext(async (_, { headers, ...rest }) => {
    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      console.log(error);
    }

    if (!token) {
      token = getToken();
    }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      IssueDefinition: {
        // needed because the issue definition key can be repeated
        keyFields: ['id', 'name', 'description']
      },
      Credential: {
        keyFields: ['id', 'user_id', 'shell', 'hash']
      }
    },
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'Dependency':
        case 'DenormalizedComponent':
        case 'IssueInstance':
        case 'StringVersion':
          return;
        case 'Asset':
          return responseObject.__typename + `:${responseObject?.componentId || responseObject?.id}`;
        case 'AssetEdge':
          return responseObject.__typename + `:${responseObject.node.__ref}`;
        case 'AssetsConnection':
          return responseObject.__typename + `:${responseObject.edges.at(0)}to${responseObject.edges.at(-1)}`;
        case 'AssetsResponse':
          return responseObject.__typename + `:${responseObject?.assets.at(0)?.componentId}to${responseObject?.assets.at(-1)?.componentId}`;
        case 'AssetVulnerabilityRemediation':
          return responseObject.__typename + `:${responseObject?.vulnerabilityId}-${responseObject?.componentId}`;
        case 'DetectedVulnerability':
          let assetId = extractPartFromPathname();
          return responseObject.__typename + `:${assetId}:${responseObject?.cve}-${responseObject?.componentId}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    }
  });

  const httpLink1 = new HttpLink({ uri: `http://localhost:4000/graphql/v3` });

  const dynamicLink = new ApolloLink((operation, forward) => {
    // Get the value from the operation context to determine the appropriate URI
    const version = operation.getContext().version || 'v3';
    let uri = GRAPHQL_URL + version;

    // Set the URI on the operation
    operation.setContext({ uri });

    // Forward the operation to the next link
    return forward(operation);
  });

  client = new ApolloClient({
    connectToDevTools: true,
    link: authLink.concat(dynamicLink).concat(httpLink1),
    name: user?.org_id || 'netrise',
    version: CONSTANTS.APP_VERSION || '1.0',
    cache,
    defaultOptions: {
      mutate: { errorPolicy: 'none' }
    }
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWithAuth0;
