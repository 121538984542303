import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import ApolloProviderWithAuth0 from './ApolloProviderWithAuth0';

import { Buffer } from 'buffer';
window.Buffer = Buffer;

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
  console.log = function () {};
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT
  });
  Sentry.setTag('service', 'FE');
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0ProviderWithHistory>
      <ApolloProviderWithAuth0>
        <App />
      </ApolloProviderWithAuth0>
    </Auth0ProviderWithHistory>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
