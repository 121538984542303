import { alpha } from '@mui/material';

export default theme => ({
  rowHover: {
    '&:hover': {
      backgroundColor: `${theme.palette.action.hover} !important`
    }
  },
  overrideNoWrap: {
    '& > td': {
      whiteSpace: 'unset !important',
      wordBreak: 'break-word'
    }
  }
});
