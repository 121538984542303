import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import useNRLazyQuery from '../../../graphql/useNRLazyQuery';
import { PLACEHOLDER_QUERY } from '../../../graphql/queries/default';
import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';

function NRSelectAll({ qlQuery, qlObjKey, qlArgs, itemsCount, setAllItems, allItems }) {
  const theme = useTheme();

  const [qlResult, loading, called, qlMakeRequest] = useNRLazyQuery({
    query: qlQuery || PLACEHOLDER_QUERY,
    qlObjKey: qlObjKey,
    defaultValue: {},
    transformData: data => {
      let itemsToSet = data.edges.map(item => item.node);
      setAllItems(itemsToSet);
      return data;
    },
    options: { fetchPolicy: 'no-cache', context: { version: 'v3' }, variables: { args: { ...qlArgs, cursor: { first: 10000 } } } }
  });

  useEffect(() => {
    return () => setAllItems([]);
  }, []);

  return (
    <Box sx={{ marginRight: 'auto', marginLeft: 1, display: 'flex', color: theme.palette.secondary.contrastText }}>
      <Box mr={1}>|</Box>
      {!allItems.length ? (
        <>
          <Typography
            variant="body2"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => qlMakeRequest()}
          >{`Select all ${itemsCount} items`}</Typography>
          {loading && <CircularProgress sx={{ marginLeft: 1 }} size={16} />}
        </>
      ) : (
        <Typography
          variant="body2"
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setAllItems([])}
        >{`Clear selection`}</Typography>
      )}
    </Box>
  );
}
export default NRSelectAll;
