import { useContext } from 'react';
import { UserContext } from '../App';
import { useSnackbar } from 'notistack';
import CustomError from '../components/CustomError/CustomError';
import { useLocation } from 'react-router-dom';
import { simplifyUrl } from './urlInterceptor.utils';

const useSessionStorageService = () => {
  const { email, currentOrgId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const getSessionStorageKey = () => {
    if (!email || !currentOrgId) {
      //enqueueSnackbar(<CustomError message={'User or organization information not found.'} />, { variant: 'error', preventDuplicate: true });
      return null;
    }
    return `${email}_${currentOrgId}`;
  };

  const getSessionStorageData = () => {
    const sessionStorageKey = getSessionStorageKey();
    if (!sessionStorageKey) return null;

    try {
      const data = sessionStorage.getItem(sessionStorageKey);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      enqueueSnackbar(<CustomError message={`Error recovering data for: "${sessionStorageKey.split('_')[0]}": ${error}`} />, {
        variant: 'error',
        preventDuplicate: true
      });
      return {};
    }
  };

  const setSessionStorageData = data => {
    const sessionStorageKey = getSessionStorageKey();
    if (!sessionStorageKey) return;

    try {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
    } catch (error) {
      enqueueSnackbar(<CustomError message={`Error saving data for: "${sessionStorageKey.split('_')[0]}": ${error}`} />, {
        variant: 'error',
        preventDuplicate: true
      });
    }
  };

  const getSessionKeyData = key => {
    const rootData = getSessionStorageData();
    return rootData ? rootData[key] : null;
  };

  const setSessionKeyData = (key, value) => {
    const rootData = getSessionStorageData() || {};
    rootData[key] = value;
    setSessionStorageData(rootData);
  };

  const setSessionData = value => {
    const key = simplifyUrl(location);
    const rootData = getSessionStorageData() || {};
    rootData[key] = value;
    setSessionStorageData(rootData);
  };

  function hasValue(value) {
    if (value == null || (Array.isArray(value) && !value?.length && typeof value !== 'object')) {
      return false; // Not an object or is null/array
    } else if (typeof value !== 'object') {
      return true;
    }

    // this means it is an object
    const keys = Object.keys(value);

    if (keys.length === 0) {
      return false; // Empty object
    }

    return true; // Object has other properties
  }

  const setSessionDataWithSetter = (setter, value, fieldKey) => {
    const key = simplifyUrl(location);
    const rootData = getSessionStorageData() || {};
    if (!hasValue(value)) {
      setter(undefined, 'replaceIn');
      removeSessionKeyDataField(key, fieldKey);
    } else {
      setter(value, 'replaceIn');
      rootData[key] = { ...rootData[key], [fieldKey]: value };
      setSessionStorageData(rootData);
    }
  };

  const clearSessionKeyData = customKey => {
    const key = customKey || simplifyUrl(location);

    const rootData = getSessionStorageData();
    if (rootData && key in rootData) {
      delete rootData[key];
      setSessionStorageData(rootData);
    }
  };

  const removeSessionKeyDataField = (key, fieldName) => {
    if (!key || !fieldName) return;
    const rootData = getSessionStorageData();
    if (rootData && key in rootData) {
      if (fieldName in rootData[key]) {
        delete rootData[key][fieldName];
        if (Object.keys(rootData[key])?.length === 0) {
          delete rootData[key];
        }
        setSessionStorageData(rootData);
      }
    }
  };

  const clearOrgSessionData = () => {
    const rootKey = getSessionStorageKey();
    if (rootKey) {
      sessionStorage.removeItem(rootKey);
    }
  };

  const clearUserSessionData = () => {
    if (email) {
      let sessionStorageKeys = Object.keys(sessionStorage);

      sessionStorageKeys.forEach(sessionStorageKey => {
        if (sessionStorageKey.startsWith(email)) {
          sessionStorage.removeItem(sessionStorageKey);
        }
      });
    }
  };

  return {
    getSessionStorageData,
    setSessionStorageData,
    getSessionKeyData,
    setSessionKeyData,
    removeSessionKeyDataField,
    clearSessionKeyData,
    clearOrgSessionData,
    clearUserSessionData,
    setSessionData,
    setSessionDataWithSetter
  };
};

export default useSessionStorageService;
