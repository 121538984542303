import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSessionStorageService from './session';
import { decodeParam, encodeParam } from './params.utils';
import { simplifyUrl } from './urlInterceptor.utils';

function excludedParams(currentUrl, paramsToSave) {
  switch (currentUrl) {
    case '/':
      return Object.keys(paramsToSave).every(paramName => ['code', 'state'].includes(paramName));
  }
}

const useUrlInterceptor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getSessionKeyData, setSessionKeyData, setSessionData } = useSessionStorageService();

  const isBackNavigation = useRef(false);
  useEffect(() => {
    const handlePopState = () => {
      isBackNavigation.current = true;
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const currentUrl = location.pathname;
      const queryParams = new URLSearchParams(location.search);
      const parsedUrl = simplifyUrl(location);

      // Check for existing saved params
      const savedParams = getSessionKeyData(parsedUrl);

      if (!queryParams.toString() && savedParams && !isBackNavigation?.current) {
        // If no params in the current URL, restore saved ones
        const restoredQuery = new URLSearchParams();

        // Iterate over saved params and encode them
        Object.keys(savedParams).forEach(key => {
          restoredQuery.append(key, encodeParam(savedParams[key]));
        });

        // Only navigate if the restored query is different from the current URL
        if (restoredQuery.toString() !== queryParams.toString()) {
          navigate(`${currentUrl}?${restoredQuery.toString()}`, { replace: true });
        }
      } else if (queryParams.toString()) {
        // Save the current params, encoding objects/arrays properly
        const paramsToSave = {};
        queryParams.forEach((value, key) => {
          paramsToSave[key] = decodeParam(value);
        });

        // Only set session data if the current params are different from the saved ones
        if (JSON.stringify(paramsToSave) !== JSON.stringify(savedParams) && !excludedParams(currentUrl, paramsToSave)) {
          setSessionData(paramsToSave);
          if (isBackNavigation?.current) {
            isBackNavigation.current = false;
          }
        }
      } else {
        if (isBackNavigation?.current) {
          isBackNavigation.current = false;
        }
      }
    }, 0);
  }, [location, navigate, getSessionKeyData, setSessionKeyData]);
};

export default useUrlInterceptor;
