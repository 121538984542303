import NRActorIcon from '../components/../NRIcons/NRActorIcon';
import NRAttackIcon from '../components/../NRIcons/NRAttackIcon';
import NRBotnetIcon from '../components/../NRIcons/NRBotnetIcon';
import NRRansomwareIcon from '../components/../NRIcons/NRRansomwareIcon';
import NRExploitCodeIcon from '../components/../NRIcons/NRExploitCodeIcon';
import LinuxIcon from '../NRIcons/OSIcons/LinuxIcon';
import RTOSIcon from '../NRIcons/OSIcons/RTOSIcon';
import VxIcon from '../NRIcons/OSIcons/VxIcon';
import WindowsIcon from '../NRIcons/OSIcons/WindowsIcon';
export const TABLE_NOT_FOUND = 'Error 404: Not found: Table';

export const HAS_EXPLOITS = 'HAS_EXPLOITS';
export const PACKAGE_MATCH = 'PACKAGE_MATCH';
export const DARK_APPEARANCE = 'dark';
export const LIGHT_APPEARANCE = 'light';
export const SYSTEM_APPEARANCE = 'system';
export const SELECTED_APPEARANCE = '|selected_appearance';

export const CONSTANTS = {
  ACTIONS: {
    BACK: 'Back',
    CLOSE: 'Close',
    CREATE: 'Create',
    FINISH: 'Finish',
    NEXT: 'Next',
    RESET: 'Reset',
    SAVE: 'Save',
    UPLOAD: 'Upload Asset'
  },
  ALGO: {
    RSA: 'rsa',
    DSA: 'dsa',
    ECDSA: 'ecdsa'
  },
  ANALYTICS: {
    KNOWN_EXPLOITED_VULNERABILITIES: 'KNOWN_EXPLOITED_VULNERABILITIES',
    KNOWN_EXPLOITED_VULNERABILITIES_ASSOCIATED_CVES: 'KNOWN_EXPLOITED_VULNERABILITIES_ASSOCIATED_CVES',
    HIGH_PROFILE_VULNERABILITIES: 'HIGH_PROFILE_VULNERABILITIES',
    HIGH_PROFILE_VULNERABILITIES_DETAILS: 'HIGH_PROFILE_VULNERABILITIES_DETAILS',
    HIGH_PROFILE_VULNERABILITIES_ASSOCIATED_CVES: 'HIGH_PROFILE_VULNERABILITIES_ASSOCIATED_CVES',
    VULNERABILITY_OF_INTEREST: 'VULNERABILITY_OF_INTEREST',
    VULNERABILITY_BY_EPSS_RATING: 'VULNERABILITY_BY_EPSS_RATING',
    VULNERABILITY_BY_PRIORITY_SCORE: 'VULNERABILITY_BY_PRIORITY_SCORE',
    FIRMWARE_BY_RISK_CATEGORY: 'FIRMWARE_BY_RISK_CATEGORY',
    VULNERABILITY_BY_SEVERITY_AND_AGE: 'VULNERABILITY_BY_SEVERITY_AND_AGE',
    VULNERABILITY_BY_DEPENDENCY: 'VULNERABILITY_BY_DEPENDENCY',
    VULNERABILITY_BY_DEPENDENCY_WITH_SEVERITY: 'VULNERABILITY_BY_DEPENDENCY_WITH_SEVERITY',
    KNOWN_ATTACKS: 'KNOWN_ATTACKS',
    THREAT_ACTORS: 'THREAT_ACTORS',
    KNOWN_ATTACKS_ASSOCIATED_CVES: 'KNOWN_ATTACKS_ASSOCIATED_CVES',
    THREAT_ACTORS_ASSOCIATED_CVES: 'THREAT_ACTORS_ASSOCIATED_CVES',
    ATTACK_TYPES: 'ATTACK_TYPES',
    THREAT_ACTORS_DETAILS: 'THREAT_ACTORS_DETAILS',
    VULNERABILITIES: 'VULNERABILITIES',
    KNOWN_EXPLOITS: 'KNOWN_EXPLOITS',
    VULNERABILITIES_SUMMARY_COUNT: 'VULNERABILITIES_SUMMARY_COUNT',
    SBOM_VULNERABILITIES_SUMMARY_COUNT: 'SBOM_VULNERABILITIES_SUMMARY_COUNT',
    HASHES: 'HASHES',
    V3: {
      ASSETS_COUNT: 'ASSETS_COUNT',
      ASSETS_FILES_COUNT: 'ASSETS_FILES_COUNT',
      ASSETS_BY_RISK_CATEGORY: 'ASSETS_BY_RISK_CATEGORY',
      ASSETS_BY_PROCESSING_STATUS: 'ASSETS_BY_PROCESSING_STATUS'
    }
  },
  AUTH: {
    NOT_AUTHENTICATED: 'Not authenticated.',
    SESSION_EXPIRED: 'Session expired.'
  },
  BINARIES_PROTECTION: {
    NAME: 'Binary Protections',
    CATEGORIES: {
      RELRO: 'RELRO',
      STACK_CANARY: 'STACKCANARY',
      NX: 'NX',
      PIE: 'PIE'
    },
    LABEL: {
      RELRO: 'RELRO',
      STACK_CANARY: 'Stack Canary',
      NX: 'NX',
      PIE: 'PIE'
    },
    VALID: 'Enabled',
    INVALID: 'Disabled',
    FILTERS: {
      RELRO_FILTER: [
        { title: 'Off', value: 'off' },
        { title: 'Partial', value: 'partial' },
        { title: 'Full', value: 'full' }
      ],
      PIE_FILTER: [
        { title: 'PIE', value: 'pie' },
        { title: 'DSO', value: 'dso' },
        { title: 'REL', value: 'rel' },
        { title: 'Off', value: 'off' }
      ]
    }
  },
  CARDS: {
    ACTIVE_SCAN_JOBS: 'Active Analysis',
    CRITICAL_VULNERABILITIES: 'critical vulnerabilities',
    HIGH_VULNERABILITIES: 'high vulnerabilities',
    LOW_VULNERABILITIES: 'low vulnerabilities',
    MEDIUM_VULNERABILITIES: 'medium vulnerabilities',
    TOTAL_FILES: 'Total Files',
    TOTAL_ASSETS: 'Total Assets',
    TOTAL_ASSETS_CLICK_TOOLTIP: 'Go to Assets page.'
  },
  CHARTS: {
    FILE_NAME_PREFIX: {
      MOST_RECENT_SCAN: 'most_recent_scan_'
    },
    MOST_RECENT_SCAN_TITLE: 'Most Recent Scan',
    MOST_RECENT_SCAN_SERIES_NAME: 'Last Scan',
    MOST_RECENT_SCAN_SUBTITLE: 'Categorical Rating',
    NO_DATA_PRESENT: 'No Data Present',
    VULNERABILITIES: 'Vulnerabilities',
    HIGH_LEVEL_VULNERABILITIES: {
      TITLE: 'High-Profile Vulnerabilities',
      SUBHEADER: 'Found in Assets',
      TOTAL_EXPLOITS: 'Unique Exploits',
      ASSOCIATED_CVES: 'ASSOCIATED CVES',
      AFFECTED_REPORTS: 'AFFECTED ASSETS',
      DRAWER: {
        TITLE: 'High-Profile Vulnerabilities',
        HIGH_LEVEL_VULNERABILITIES_DETAILS: {
          TABLE_TITLE: 'Unique Exploits - (${0})',
          CARD_SUBTITLE: 'Associated CVEs',
          ASSOCIATED_CVE_TABLE: {
            ATTACK_TYPES: 'Attacks Associated CVEs - (${0})',
            THREAT_ACTORS: 'Actors Associated CVEs - (${0})'
          },
          ATTACK_TYPES_CVE_TABLE: '',
          FILTERS: {
            VALUES: [
              {
                value: 'ATTACK_NAME',
                name: 'Attack Name',
                field: 'name'
              },
              {
                value: 'IN_ASSETS',
                name: 'In Assets',
                field: 'correlations'
              },
              {
                value: 'CVE_COUNT',
                name: 'CVE count',
                field: 'cveCount'
              },
              {
                value: 'ATTACK_TYPE',
                name: 'Attack Type',
                field: 'type'
              }
            ],
            ORDER: {
              ASC: 'ASC',
              DESC: 'DESC'
            }
          }
        },
        ASSOCIATED_CVE: {
          TABLE_TITLE: 'Associated CVEs - (${0})', // ${0} = number of associated CVEs,
          ROWS: {
            CVE_ID: 'CVE ID',
            ATTACKS: 'Attacks',
            IN_ASSETS: 'In Assets'
          },
          CORRELATIONS_TOOLTIP_A: 'This vulnerability is found in ',
          CORRELATIONS_TOOLTIP_B: ' other assets.'
        },
        AFFECTED_REPORTS: {
          TABLE_TITLE: 'Affected Assets - (${0})' // ${0} = number of Affected Assets,
        }
      }
    },
    CISA_KEV: {
      TITLE: 'CISA Known Exploited Vulnerabilities',
      SUBHEADER: 'Found in Assets',
      TOTAL_KEVS: 'Unique KEVs',
      PAST_DUE: 'Past Due',
      AFFECTED_REPORTS: 'AFFECTED ASSETS',
      DRAWER: {
        TITLE: 'CISA Known Exploited Vulnerabilities',
        TOTAL_KEVS: {
          TABLE_TITLE: 'Associated CVEs - (${0})', // ${0} = number of associated CVEs,
          ROWS: {
            CVE_ID: 'CVE ID',
            DUE_DATE: 'CISA Due Date',
            COMPONENT: 'Component',
            IN_ASSETS: 'In Assets'
          },
          CORRELATIONS_TOOLTIP_A: 'This vulnerability is found in ',
          CORRELATIONS_TOOLTIP_B: ' other assets.'
        },
        PAST_DUE: {
          TABLE_TITLE: 'Associated CVEs - (${0})' // ${0} = number of associated CVEs,
        },
        AFFECTED_REPORTS: {
          TABLE_TITLE: 'Affected Assets - (${0})' // ${0} = number of Affected Assets,
        }
      }
    },
    KNOWN_EXPLOITS: {
      CISA_KEV: 'CISA KEV',
      THREAT_ACTORS: 'Threat Actors',
      ATTACKS: 'Attacks',
      BOTNETS: 'Botnets',
      RANSOMWARE: 'Ransomware',
      ASSOCIATED_CVES: 'ASSOCIATED CVES',
      EXPLOIT_CODE: 'Exploit Code',
      TOTAL_POC: 'Proof of Concept',
      TOTAL_WEAPONIZED: 'Weaponized'
    },
    THREAT_ACTORS: {
      TITLE: 'Threat Actors',
      SUBHEADER: 'Found in Reports',
      TOTAL_ACTORS: 'TOTAL ACTORS',
      ASSOCIATED_CVES: 'ASSOCIATED CVES',
      AFFECTED_REPORTS: 'AFFECTED ASSETS',
      DRAWER: {
        TITLE: 'Threat Actors',
        ASSOCIATED_CVE: {
          TABLE_TITLE: 'Associated CVEs - (${0})', // ${0} = number of associated CVEs,
          ACTORS: 'Actors'
        },
        AFFECTED_REPORTS: {
          TABLE_TITLE: 'Affected Assets - (${0})' // ${0} = number of Affected Assets,
        },
        THREAT_ACTORS_DETAILS: {
          TABLE_TITLE: 'Total Actors - (${0})',
          CARD_SUBTITLE: 'Associated CVEs - ${0}',
          FILTERS: {
            VALUES: [
              {
                value: 'THREAT_ACTOR_NAME',
                name: 'Threat Actor Name',
                field: 'name'
              },
              {
                value: 'IN_IMAGES',
                name: 'In Assets',
                field: 'correlations'
              },
              {
                value: 'CVE_COUNT',
                name: 'CVE count',
                field: 'cveCount'
              }
            ],
            ORDER: {
              ASC: 'ASC',
              DESC: 'DESC'
            }
          }
        }
      }
    },
    KNOWN_ATTACKS: {
      TITLE: 'Known Attacks',
      SUBHEADER: 'Leveraging Detected Vulnerabilities',
      ATTACK_TYPES: 'ATTACK TYPES',
      ASSOCIATED_CVES: 'ASSOCIATED CVES',
      AFFECTED_REPORTS: 'AFFECTED ASSETS',
      DRAWER: {
        TITLE: 'Known Attacks',
        ATTACK_TYPES: {
          TABLE_TITLE: 'Attack Types - (${0})',
          FILTERS: {
            VALUES: [
              {
                value: 'ATTACK_NAME',
                name: 'Attack Name'
              },
              {
                value: 'IN_IMAGES',
                name: 'In Assets'
              }
            ],
            ORDER: {
              ASC: 'ASC',
              DESC: 'DESC'
            }
          }
        },
        ASSOCIATED_CVE: {
          TABLE_TITLE: 'Associated CVEs - (${0})',
          ATTACKS: 'Attacks'
        },
        AFFECTED_REPORTS: {
          TABLE_TITLE: 'Affected Assets - (${0})'
        }
      }
    },
    BY_RISK_CATEGORY: {
      EXPORT_FILENAME_PREFIX: 'assets_reports_by_risk_category-',
      LABELS_TITLE: 'RISK CATEGORY',
      TITLE: 'Asset Reports',
      TOOLTIP:
        'This chart displays how many Assets fall into each Risk Category. A Risk Category is assigned to an Asset based the number and severity of vulnerabilities found. The more severe the Risk Category, the greater risk the Asset presents to your Organization. NetRise recommends investigating Assets with the highest Risk Category first.',
      SUBTITLE: 'By Risk Category',
      TOTAL_REPORTS: 'Total Reports',
      FILTERS: {
        VALUES: [
          {
            value: 'RISKSCORE',
            name: 'Risk Score'
          },
          {
            value: 'VENDOR',
            name: 'Vendor'
          },
          {
            value: 'PRODUCT',
            name: 'Product'
          },

          {
            value: 'CREATEDAT',
            name: 'Timestamp'
          },
          {
            value: 'VERSION',
            name: 'Version'
          }
        ],
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        }
      }
    },
    BY_EPSS_RATING: {
      SUBHEADER: 'By Priority Score',
      CATEGORIES: ['Priority 5', 'Priority 4', 'Priority 3', 'Priority 2', 'Priority 1'],
      SERIES_NAME: 'Rating',
      EXPORT_FILENAME_PREFIX: 'vulnerabilities_by_priority_score-',
      UNIQUE: 'uniques',
      UNIQUE_TOOLTIP: 'Each distinct identifier of a vulnerability is counted a single time across an Organization.',
      OCCURRENCES_TOOLTIP: 'Counts all instances of a particular vulnerability found across an Organization.',
      OCCURRENCES: 'occurrences',
      TOOLTIP: '* ',
      DRAWER_TITLE: 'Vulnerabilities by Priority Score'
    },
    VULNERABILITY_OF_INTEREST: {
      EXPORT_FILENAME_PREFIX: 'vulnerabilities_of_interest-',
      CATEGORIES: { BOTNETS: 'Botnets', IN_THE_WILD: 'In the Wild', RANSOMWARE: 'Ransomware', THREAT_ACTORS: 'Threat Actors' },
      FILTERS: { ['Botnets']: 'inBotnets', ['In the Wild']: 'inTheWild', ['Ransomware']: 'inRansomware', ['Threat Actors']: 'inThreatActors' },
      OCCURRENCES: 'occurrences',
      SERIES_NAME: 'Total Occurrences',
      SUBHEADER: 'Of Interest',
      UNIQUE: 'uniques',
      UNIQUE_TOOLTIP: 'Each distinct identifier of a vulnerability is counted a single time across an Organization.',
      OCCURRENCES_TOOLTIP: 'Counts all instances of a particular vulnerability found across an Organization.',
      DRAWER_TITLE: 'Vulnerabilities Of Interest',
      TOOLTIP:
        'This chart displays how many vulnerabilities are known to be used by various types of threat actors. Special attention should be paid to Assets with these vulnerabilities present. Use the Occurrences/Uniques toggle to show the total number of vulnerabilities or the unique occurrences of each.'
    },
    VULNERABILITY_BY_AGE: {
      CATEGORIES: ['15 days', '30 days', '1+ year'],
      DEFAULT_DATA: [
        { name: 'LOW', data: [0, 0, 0] },
        { name: 'MEDIUM', data: [0, 0, 0] },
        { name: 'HIGH', data: [0, 0, 0] },
        { name: 'CRITICAL', data: [0, 0, 0] }
      ],
      SUBHEADER: 'By age (days)',
      EXPORT_FILENAME_PREFIX: 'vulnerabilities_by_age-',
      TOOLTIP:
        'This chart displays the total vulnerability count in your Organization grouped by severity and the date that the vulnerability was discovered. It helps identify any long-term exposure associated with old vulnerabilities as well as newly discovered vulnerabilities which could be used by opportunistic attackers.',
      DRAWER_TITLE: 'Vulnerabilities by Age'
    }
  },
  COMPONENT_MAKEUP: {
    ANALYTIC: 'COMPONENT_MAKEUP'
  },
  CONFIRMATION_MODAL: {
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
    CLOSE_TOOLTIP: 'Close'
  },
  COPY_PATH_TO_CLIPBOARD: 'Copy path to clipboard',
  COPY_TO_CLIPBOARD_TOOLTIP: 'Copy data to clipboard as CSV',
  COPIED_TO_CLIPBOARD_MSG: 'Data copied to clipboard.',
  CRYPTOGRAPHY: {
    CERTIFICATES: 'Certificates',
    CRYPTOGRAPHY: 'Cryptography',
    PUBLIC_KEY: 'Public Keys',
    PRIVATE_KEY: 'Private Keys',
    CERTIFICATES_INVALID: 'Invalid Certificates',
    CERTIFICATES_VALID: 'Valid Certificates',
    PUBLIC_KEY_INVALID: 'Found Private Key',
    PUBLIC_KEY_VALID: 'Valid Public Keys',
    PRIVATE_KEY_INVALID: 'Found Public Key',
    PRIVATE_KEY_VALID: 'Valid Private Keys',
    KEYS: {
      PUBLIC_KEY: 'publicKey',
      PRIVATE_KEY: 'privateKey',
      CERTIFICATES: 'certificates'
    }
  },
  CRYPTOGRAPHY_COUNT: {
    ANALYTIC: 'CRYPTOGRAPHY_COUNT'
  },
  DEFAULT_VALUES: {
    NO_KNOWN_MALWARE: 'NO KNOWN MALWARE',
    NO_KNOWN_ACTORS: 'NO KNOWN ACTORS',
    NO_KNOWN_ATTACKS: 'NO KNOWN ATTACKS'
  },
  DATE_FORMAT: {
    HEADER: 'MM/DD/YY hh:mm A',
    DAY_MONTH_YEAR: 'DD MMMM YYYY',
    MONTH_DAY_YEAR: 'MMMM DD, YYYY',
    MONTH_DAY_YEAR_TIME: 'MMMM Do YYYY, h:mm:ss a',
    MONTH_DAY_YEAR_TIME_SHORT: 'MM/DD/YY h:mm A',
    MONTH_DAY_YEAR_SHORT: 'MM/DD/YYYY',
    YEAR_MONTH_DAY_TIME_DASHED: 'YYYY-MM-DD__hh_mm_ss',
    ANALYSIS_LOG: 'YYYY-MM-DD[@]hh:mm:ss',
    ANALYSIS_LOG_THRESHOLDS: { ss: 3, s: 40, m: 59, h: 48, d: 60, w: 4, M: 10 },
    JOB_DURATION: 'H [hrs,] mm [mins,] ss [secs]'
  },
  DEVICE_INVENTORY_TABLE: {
    ADDRESS: 'ADDRESS',
    TYPE: 'TYPE',
    VENDOR: 'VENDOR',
    MODEL: {
      VALUE: 'MODEL',
      TOOLTIP: 'Product Model'
    },
    VERSION: 'VERSION',
    LAST_SCAN: 'LAST SCAN',
    VULNERABILITIES: 'VULNERABILITIES'
  },
  DOWNLOAD: {
    COMPONENT: 'COMPONENT',
    EXTRACTED: 'EXTRACTED',
    ALREADY_IN_PROGRESS_MESSAGE: 'File generation already in progress',
    SUCCESS_MESSAGE: 'Generated successfully, download initiated'
  },
  DOWNLOAD_SBOM: {
    TOOLTIPS: {
      KNOWN_UNKNOWNS:
        'Known Unknowns are any files that NetRise detects but cannot provide high-fidelity information around. Toggling this will provide file names, paths, and hashes for any Known Unknown found.',
      VULNERABILITIES: 'Enable to include identified CVEs and vulnerability advisory references for each component.',
      NETRISE_FIDELITY:
        'Enable to include a custom property displaying NetRise’s identification confidence. Possible values are Low, Medium, High, and Certain.',
      VEX_ANALYSIS:
        'Vulnerability Exploitability eXchange (VEX) is a form of a security advisory. The goal of VEX is to allow a software supplier or other parties to assert the status of specific vulnerabilities in a particular product.'
    }
  },
  ERRORS: {
    INDEX_NOT_FOUND: 'INDEX_NOT_FOUND'
  },
  EXPLOITS: {
    KNOWN_ACTORS: {
      NAME: 'Known Actors',
      ICON: <NRActorIcon />,
      KEY: 'inThreatActors'
    },
    KNOWN_ATTACKS: {
      NAME: 'Known Attacks',
      ICON: <NRAttackIcon />,
      KEY: 'inKnownAttacks'
    },
    KNOWN_BOTNETS: {
      NAME: 'Known Botnets',
      ICON: <NRBotnetIcon width={24} height={24} />,
      KEY: 'inBotnets'
    },
    KNOWN_RANSOMWARE: {
      NAME: 'Known Ransomware',
      ICON: <NRRansomwareIcon width={24} height={24} />,
      KEY: 'inRansomware'
    },
    KNOWN_EXPLOIT_CODE: {
      NAME: 'Exploit Code',
      ICON: <NRExploitCodeIcon width={24} height={24} />,
      KEY: 'exploits'
    }
  },
  FALSE: 'False',
  FILE_BROWSER: {
    DRAWER: {
      COPY_TO_CLIPBOARD_TOOLTIP: 'Copy to clipboard as CSV',
      FILE_NOT_FOUND: 'File not Found.',
      FOLDER_NOT_FOUND: 'Unable to resolve the requested folder.'
    },
    ROOT_FOLDER_NAME: '/',
    LOAD_CONTENT: 'Load Folder Content'
  },
  FILTER_OPERATIONS: {
    EQUAL: 'EQUAL',
    NOT_EQUAL: 'notEqual',
    CONTAINS: 'contains',
    ENUM: 'ENUM'
  },
  FIRMWARE_IMAGE: 'Firmware Image',
  TABLE_UPDATE: {
    SUCCESS_MESSAGE: 'Table saved successfully.',
    ERROR_MESSAGE: 'Table was unable to save, please check your work.'
  },
  GENERICS: {
    NO: 'No',
    YES: 'Yes'
  },
  ANALYSIS_STATUS: {
    CREATED: 'created',
    PAUSED: 'paused',
    STARTED: 'started',
    CANCELED: 'canceled',
    STOPPED: 'stopped',
    COMPLETED: 'completed',
    FAILED: 'failed',
    LABELS: {
      completed: 'Complete',
      started: 'In Progress',
      failed: 'Failed'
    }
  },
  JOB_CREATE: {
    FIRMWARE_LABEL: 'Firmware',
    FIRMWARE_HELPER_TEXT: 'Select the firmware to be processed.',
    JOB_DEFINITION_DETAILS_LABEL: 'Job definition description',
    JOB_DEFINITION_LABEL: 'Job Definition',
    JOB_DEFINITION_HELPER_TEXT: 'Job definition notes.',
    NOTES_LABEL: 'Label',
    NOTES_HELPER_TEXT: 'Additional information to identify the Job.',
    STEPPER: {
      STEP_NAME_0: 'Select the job definition',
      STEP_NAME_1: 'Additional information to identify the Job',
      STEP_NAME_2: 'Select the firmware to be processed',
      STEP_NAME_3: 'Review'
    },
    SUBHEADER: 'Add a new Job to the platform.',
    TITLE: 'Create Job'
  },
  JOB_DEFINITION_CREATE: {
    DESCRIPTION_LABEL: 'Description',
    DESCRIPTION_HELPER_TEXT: 'The description of the job-definition.',
    NAME_LABEL: 'Name',
    NAME_HELPER_TEXT: 'The name of the job definition.',
    STEPPER: {
      STEP_NAME_0: 'Describe the job definition',
      STEP_NAME_1: 'Select the tasks to be ran in the job',
      STEP_NAME_2: 'Review'
    },
    SUBHEADER: 'Add a new Job definition to the platform.',
    TITLE: 'Create Job definition'
  },
  JOB_TABLE: {
    TOOLTIPS: {
      REPORT: 'Go to Report'
    }
  },
  JOB_VIEW: {
    DURATION: 'Duration: ',
    JOB_ID_TITLE: 'Job #',
    PIPELINE_HEADER: 'Pipeline',
    PIPELINE_SUBHEADER: 'Ordered list of tasks.',
    SELECTED_TASK_NAME: 'Task Name:',
    SELECTED_TASK_DESCRIPTION: 'Task Description:',
    SELECTED_TASK_CONSUMER_SERVICE: 'Consumer Service:'
  },
  LICENSES: {
    ANALYTIC: 'LICENSES'
  },
  LOGIN: {
    EMAIL_HELPER_TEXT: 'Enter your registered email.',
    EMAIL_IS_REQUIRED: 'Email is required.',
    INVALID_CREDENTIALS: 'Invalid credentials.',
    INVALID_EMAIL: 'Invalid email address.',
    LOGIN_ERROR: 'Invalid email and/or password.',
    NOT_AUTHENTICATED: 'Not Authenticated.',
    PASSWORD_HELPER_TEXT: 'Type your password.',
    LAST_LOGIN: 'last login about',
    NOT_LOGGED_YET: 'has not logged in yet'
  },
  MESSAGES: {
    NAME_CHANGED_SUCCESS: 'Name changed successfully.',
    PASSWORD_CHANGED_SUCCESS: 'Password changed successfully.',
    GROUPS_ADDED_SUCCESSFULLY: 'Groups added successfully',
    GROUPS_UPDATED_SUCCESSFULLY: 'Groups updated successfully',
    GROUPS_REMOVED_SUCCESSFULLY: 'Groups deleted successfully',
    GROUP_REMOVED_SUCCESSFULLY: 'Group deleted successfully',
    ASSET_REMOVED_FROM_GROUP_SUCCESSFULLY: 'Asset deleted from Group successfully',
    ASSET_REMOVED_FROM_GROUP_ERROR: 'There was an error deleting the asset from the group, please try again later.',
    GROUP_REMOVED_ERROR: 'There was an error deleting the group, please try again later.',
    ASSET_GROUP_RESULT_SUCCESS: 'Group created successfully.',
    ASSET_GROUP_RESULT_NAME_EXISTS: 'Group name already exists.',
    ASSET_GROUP_RESULT_UNSPECIFIED: 'Group creation failed.'
  },
  MIME_TYPES: {
    DIRECTORY: 'directory',
    TREAT_AS_DIRECTORY: ['directory', 'inode/directory'],
    CANT_DOWNLOAD: ['inode/symlink', 'inode/directory', 'directory']
  },
  MISCONFIGURATION_CHECKS: {
    ANALYTIC: 'MISCONFIGURATION_CHECK',
    DONUT_CHART: {
      CHART_TITLE: 'Checks Results',
      CATEGORIES: ['Failed', 'Passed', 'Not Applicable']
    },
    LABELS: {
      FAILED: 'Failed',
      PASSED: 'Passed',
      NOT_APPLICABLE: 'Not Applicable',
      TOTAL: 'Total'
    },
    DATA: {
      FAILED: 'failed',
      PASSED: 'passed',
      NOT_APPLICABLE: 'notApplicable'
    },
    STATUSES: {
      FAILED: 'FAILED',
      PASSED: 'PASSED',
      NOT_APPLICABLE: 'NOT_APPLICABLE'
    }
  },
  MISCONFIGURATION_BY_CATEGORY: {
    ANALYTIC: 'MISCONFIGURATION_BY_CATEGORY'
  },
  NETRISE: 'netrise',
  ORGANIZATION: {
    DISABLED: 'Disabled',
    ENABLED: 'Enabled',
    NOT_VERIFIED: 'Not Verified',
    VERIFIED: 'Verified'
  },
  ORIGIN_DATE: '1970-01-01T00:00:00.000Z',
  ORGANIZATION_TEXT: {
    MY_PROFILE: 'My Profile',
    INVITE_TO_ORGANIZATION: 'Invite To Organization',
    ERROR_EMAIL: 'You must specify a valid email',
    HELPER_TEXT_EMAIL: 'The email address of the person you wish to invite.',
    PLACEHOLDER_FILTER: 'Filter Users...'
  },
  ORGANIZATION_ROLE_TOOLTIP: {
    ADMIN: 'The Admin account is responsible for administering all other accounts including promotion and demotion of account roles.',
    MEMBER: "Member accounts have read-only access to the organization's data in the platform.",
    OPERATOR:
      "Operator accounts have read and write access to the organization's data in the platform. They cannot invite, promote, demote, or otherwise administer other accounts.",
    OWNER:
      'Owner accounts have read and write access to the organization’s data in the platform. They are responsible for administering other accounts including inviting, promoting, demoting, and deleting.',
    CHANGE_USER_ROLE_SUCCESS_MESSAGE: 'User role successfully updated!'
  },
  ORGANIZATION_SUB_HEADER: {
    ADMIN: 'ACTIVE USERS',
    MEMBER: 'ORGANIZATION INFORMATION',
    OWNER: 'LICENSE VALID'
  },
  ORGANIZATION_USER_MENU: {
    OPTIONS: {
      DELETE_ACCOUNT: 'Delete User',
      DEMOTE_USER_ROLE: 'Demote User Role',
      DISABLE_ACCOUNT: 'Disable User',
      ENABLE_ACCOUNT: 'Enable User',
      PROMOTE_USER_ROLE: 'Promote User Role',
      RESET_PASSWORD: 'Reset Password',
      REMOVE_ACCESS: 'Remove User'
    }
  },
  PASSWORD: {
    TYPE_NEW_PASSWORD: 'Type the new password.',
    REQUIRED_MESSAGE: 'You must specify a password',
    PASSWORD_MIN_LENGTH: 'Password must have at least 8 characters.',
    REPEAT_PASSWORD: 'Repeat the new password.',
    MATCH_PASSWORD: 'The passwords do not match.',
    NEW_PASSWORD: 'New Password',
    CONFIRM_PASSWORD: 'Confirm New Password',
    CHANGE_PASSWORD: 'Change password'
  },
  PERMISSIONS: {
    READ_IMGS: 'read:images',
    READ_JOBS: 'read:jobs',
    READ_ORGS: 'read:orgs',
    READ_USERS: 'read:users',
    READ_TAGS: 'read:tags',
    WRITE_IMGS: 'write:images',
    WRITE_JOBS: 'write:jobs',
    WRITE_ORGS: 'write:orgs',
    WRITE_USERS: 'write:users',
    WRITE_TAGS: 'write:tags',
    WRITE_GROUPS: 'write:assetgroups',
    DELETE_GROUPS: 'delete:assetgroups',
    ERROR: `Not enough permissions`,
    ERROR_TOOLTIP_MESSAGE: 'You do not have permission to perform this action, please contact your administrator.'
  },
  REPORT: {
    ANALYTIC: 'VULNERABILITY_HIGHLIGHTED_PRODUCTS',
    CREDENTIALS_SUB_TABS: {
      USER_ACCOUNTS: 'User Accounts',
      DETECTED_HASHES: 'Detected Hashes'
    },
    CRYPTOGRAPHY_SUB_TABS: {
      CERTIFICATES: 'Certificates',
      PRIVATE_KEYS: 'Private Keys',
      PUBLIC_KEYS: 'Public Keys'
    },
    HEADER: {
      ARCHITECTURE: 'Architecture',
      PRODUCT: 'Product',
      SHA256_HASH: 'SHA256 Hash',
      OS: 'OS',
      MANUFACTURER: 'Manufacturer',
      MODEL: 'Model',
      NAME: 'Name',
      VERSION: 'Version',
      ASSET_CPE: 'CPE 2.3',
      LAST_MODIFIED: 'Last Modified',
      GROUPS: 'Groups',
      ICONS: {
        LINUX: <LinuxIcon width="48px" height="48px" />,
        RTOS: <RTOSIcon width="48px" height="48px" />,
        VX: <VxIcon width="48px" height="48px" />,
        WIN: <WindowsIcon width="48px" height="48px" />
      }
    },
    POPOVER: {
      CREATED_BY: 'Created By',
      CREATED_ON: 'Created On',
      COPIED: ' copied!',
      IMAGE_INFORMATION: 'Image Information',
      MD5_HASH: 'MD5 HASH',
      MD5_HASH_TOOLTIP: 'Copy MD5 HASH information.',
      REPORT_INFORMATION: 'Report Information',
      SHA256_HASH: 'SHA256 HASH',
      SHA256_HASH_TOOLTIP: 'Copy SHA256 HASH information.'
    },
    TABS: {
      BINARIES: 'Binaries',
      CERTIFICATES: 'Certificates',
      CREDENTIALS: 'Credentials',
      CRYPTOGRAPHY: 'Cryptography',
      FILE_SYSTEM: 'File System',
      SBOM: 'SBOM',
      SUMMARY: 'Summary',
      VULNERABILITIES: 'Vulnerabilities',
      MISCONFIGURATIONS: 'Misconfigurations',
      INDEXES: {
        SUMMARY: 0,
        VULNERABILITIES: 1,
        SBOM: 2,
        MISCONFIGURATIONS: 3,
        BINARIES: 4,
        CRYPTOGRAPHY: 5,
        CREDENTIALS: 6,
        FILE_SYSTEM: 7
      },
      KEYS: {
        CREDENTIAL: 'credential',
        HASH: 'hash'
      }
    },
    UPDATING_TOOLTIP:
      'This report is currently being updated with the latest data. While this is in progress you are free to keep working and making changes.'
  },
  REPORT_URL_COPIED: 'Report URL copied!',
  RESULTS_COPIED: 'Failed Results copied to clipboard',
  RESET_PASSWORD: {
    SUCCESS_EMAIL: 'Password reset email sent, you should receive it in a few minutes.'
  },
  RISK_METER: {
    UNDETERMINED: 'Undetermined',
    NEGLIGIBLE: 'Negligible',
    CONSERVATIVE: 'Conservative',
    MODERATE: 'Moderate',
    SIGNIFICANT: 'Significant',
    SEVERE: 'Severe'
  },
  ROLE: {
    ADMIN: 'admin',
    MEMBER: 'member',
    OWNER: 'owner',
    OPERATOR: 'operator',
    EXTRAS: {
      EXTERNAL: 'external'
    }
  },
  OFF: 'off',
  RISK_CATEGORY: {
    RISK_CATEGORY_UNSPECIFIED: 'RISK_CATEGORY_UNSPECIFIED',
    RISK_CATEGORY_NEGLIGIBLE: 'RISK_CATEGORY_NEGLIGIBLE',
    RISK_CATEGORY_CONSERVATIVE: 'RISK_CATEGORY_CONSERVATIVE',
    RISK_CATEGORY_MODERATE: 'RISK_CATEGORY_MODERATE',
    RISK_CATEGORY_SIGNIFICANT: 'RISK_CATEGORY_SIGNIFICANT',
    RISK_CATEGORY_SEVERE: 'RISK_CATEGORY_SEVERE'
  },
  SEVERITY: 'Severity',
  SEVERITIES: {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
    CRITICAL: 'critical'
  },
  SIDE_NAVBAR: {
    MENU_ITEMS: {
      ASSETS: 'Assets',
      GROUPS: 'Groups',
      OVERVIEW: 'Overview',
      FIRMWARE: 'Firmware',
      SEARCH: 'Search'
    }
  },
  SIFT_SEARCH: {
    VIEW: {
      LIST: 'LIST',
      GRAPH: 'GRAPH'
    },
    GROUPED_BY: {
      NONE: 'none',
      SHA256: 'sha256'
    }
  },
  SHOW_MORE: 'Show more...',
  SHOW_LESS: 'Show less...',
  STATUS: {
    INVALID: 'Invalid',
    EXPIRED: 'Expired',
    VALID: 'Valid'
  },
  SUCCESS: 'success',
  SUMMARY: {
    ANALYSIS_SUMMARY: {
      TITLE: 'Analysis Summary',
      FILE_SIZE: 'File Size',
      ANALYSIS_TIME: 'Analysis Time',
      UPLOADED_ON: 'Uploaded On',
      UPLOADED_BY: 'Uploaded By',
      LAST_MODIFIED: 'Last Modified',
      MODIFIED_BY: 'Modified By'
    },
    ENTRIES: {
      VULNERABILITIES: 'Vulnerabilities',
      SBOM: 'SBOM',
      MISCONFIGURATIONS: 'Misconfigurations',
      BINARIES: 'Binaries',
      CRYPTOGRAPHY: 'Cryptography',
      CREDENTIALS: 'Credentials',
      FILE_SYSTEM: 'File System'
    },
    ENTRY_KEYS: {
      VULNERABILITIES_KEY: 'vulnerability',
      SBOM_KEY: 'components',
      MISCONFIGURATIONS_KEY: 'misconfigurations',
      BINARIES_KEY: 'binaries',
      CRYPTOGRAPHY_KEY: 'cryptography',
      CREDENTIALS_KEY: 'credentials',
      FILE_SYSTEM_KEY: 'files'
    },
    BINARY_PROTECTIONS: 'Binary Protections',
    COMPONENT_PREVALENCE: 'Component Prevalence',
    CRYPTOGRAPHY: 'Cryptography',
    FIRMWARE_INFORMATION: 'Firmware Information',
    FIRMWARE_INFORMATION_SECTION: {
      ARCHITECTURE: 'Architecture',
      BITNESS_TOOLTIP: 'Bitness: ',
      MODEL: 'Model',
      KERNEL_VERSION: 'Kernel version',
      NAME_TOOLTIP: 'Firmware name',
      NO_DATA: 'no data',
      OS_TOOLTIP: 'Operating system: ',
      VERSION: 'Version'
    },
    FIRMWARE_PROFILE: 'Firmware Profile',
    HIGHLIGHTED_PRODUCTS: 'Highlighted Products',
    LICENSES: 'Component Licenses',
    MALWARE_FINDINGS: 'Malware Findings',
    OPERATING_SYSTEM: 'Operating System',
    SEVERITY_COUNT: 'Severity Count',
    TOP_OFFENDERS: 'Top Offenders',
    VENDOR_RELEASES: 'Vendor Releases',
    VULNERABILITIES: 'Vulnerabilities',
    VULNERABILITY_INFORMATION: 'Vulnerability Information',
    MISCONFIGURATION_CHECKS: 'Misconfiguration Checks',
    COMPONENT_MAKEUP: 'SBOM Component Makeup',
    RESULTS: 'Results'
  },
  TABLE: {
    FILTERS: {
      ORDER: {
        ASC: 'ASC',
        DESC: 'DESC'
      }
    },
    BINARIES_TABLE: {
      ANALYTIC: 'BINARY_PROTECTIONS_TABLE',
      ROWS: {
        NAME: 'Name',
        RELRO: 'RELRO',
        STACK_CANARY: 'Stack Canary',
        NX: 'NX',
        PIE: 'PIE',
        CORRELATIONS: 'Correlations',
        FILE: 'File Path',
        SHA256: 'Sha256'
      },
      FILTERS: {
        FIELDS: {
          name: 'NAME',
          relro: 'RELRO',
          stackCanary: 'STACKCANARY',
          nx: 'NX',
          pie: 'PIE',
          sha256: 'SHA256',
          correlations: 'CORRELATIONS',
          filePath: 'FILEPATH'
        }
      },
      TITLE: 'Detected Binaries',
      TOOLTIP: {
        STACKCANARY: {
          DEFAULT:
            'Stack Canaries are an exploit defense that writes a value on the stack which helps to prevent attackers from gaining code execution via buffer overflow attacks.',
          OFF: 'The binary was not compiled with Stack Canaries making it more susceptible to buffer overflow attacks.',
          ON: 'The binary was compiled with Stack Canaries making it less likely, but not impossible, to exploit a buffer overflow vulnerability.'
        },
        NX: {
          DEFAULT:
            'The No-Execute Bit (NX) is a technology used to segregate areas of memory to prevent code from executing in areas of memory meant for data storage. This significantly reduces the exploitability of binaries.',
          OFF: 'A No eXecute (NX) bit (also known as Data Execution Prevention; DEP) was not detected which allows all areas of memory to be executed as code increasing the overall exploitability.',
          ON: 'A No eXecute (NX) bit (also known as Data Execution Prevention; DEP) was detected which prevents certain areas of memory from being executed as code preventing certain exploits.'
        },
        PIE: {
          DEFAULT:
            'Position Independent Executables (PIE) binaries are loaded into random locations within virtual memory each time the application is executed, making them harder to exploit.',
          DSO: 'This is a Dynamic Shared Object (DSO) which is position independent by default. This makes Return Oriented Programming (ROP) attacks much more difficult.',
          OFF: 'The binary did not indicate any characteristics of a Position Independent Executable which could allow malicious actors to more easily execute Return Oriented Programming (ROP) attacks.',
          PIE: 'The binary was compiled as a Position Independent Executable (PIE) which loads the binary and all of its dependencies into random locations in memory. This makes Return Oriented Programming (ROP) attacks much more difficult.',
          REL: 'This is a RELocatable binary which means that it is loaded into a random location in memory. This makes it harder for Return Oriented Programming (ROP) attacks to succeed.'
        },
        RELRO: {
          DEFAULT: 'RELocation Read-Only (RELRO) is a generic exploit mitigation technique to harden the data sections of an ELF binary or process.',
          FULL: 'The binary was compiled with Full RELRO effectively mitigating most memory corruption attacks.',
          OFF: 'The binary was not compiled with any RELRO flags making it susceptible to buffer overflows and other memory corruption attacks.',
          PARTIAL:
            'The binary was compiled with Partial RELRO preventing the risk of Global Offset Table (GOT) buffer overflows but remains vulnerable to other GOT memory corruption attacks.'
        }
      }
    },
    CERT_TABLE: {
      CORRELATIONS_TOOLTIP_A: 'This certificate is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.',
      ACTION_TOOLTIPS: {
        DOWNLOAD_CERTIFICATE: 'Download certificate file'
      },
      FILTERS: {
        FIELDS: {
          sha256: 'SHA256',
          filePath: 'FILEPATH',
          invaliditiesCount: 'INVALIDITIESCOUNT',
          effectivePermissions: 'EFFECTIVEPERMISSIONS',
          correlations: 'CORRELATIONS'
        },
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        }
      },

      INVALID_CERTIFICATE: 'Invalid Certificate',
      INVALIDITIES: {
        EXPIRED: {
          TITLE: 'Expired',
          DESCRIPTION: 'The certificate is past its expiration date.'
        },
        CA_SELF_SIGNATURE_INVALID: {
          TITLE: 'CA Self signature invalid',
          DESCRIPTION: 'The certificate identifies itself as a certificate authority (which should be self signed) but has an invalid signature.'
        },
        PRIVATE_KEY_COMPROMISED_BY_NETRISE: {
          TITLE: 'Private key compromised by NetRise',
          DESCRIPTION: "We have the private key that is associated with the public key used by this certificate, meaning it's insecure."
        },
        PRIVATE_KEY_COMPROMISED: {
          TITLE: 'Private key compromised',
          DESCRIPTION: 'Someone, which may include us, has compromised the private key, meaning it is insecure.'
        },
        CONSTRAINT_VIOLATION: {
          TITLE: 'Constraint violation',
          DESCRIPTION: 'The certificate is attempting to be used in a way it is not allowed to.'
        },
        UNKNOWN_AUTHORITY: {
          TITLE: 'Unknown authority',
          DESCRIPTION: 'When the certificate issuer is unknown or the issuing certificate can not be acquired.'
        },
        HOSTNAME_ERROR: {
          TITLE: 'Hostname error',
          DESCRIPTION: 'When the set of authorized hostnames does not match the requested one.'
        },
        CA_NOT_AUTHED_FOR_EXTKEY_USAGE: {
          TITLE: 'CA Not authed for EXTKEY usage',
          DESCRIPTION: 'When an intermediate or root certificate does not permit a requested extended key usage.'
        },
        CA_NOT_AUTHORIZED_FOR_THIS_NAME: {
          TITLE: 'CA Not authorized for this name',
          DESCRIPTION:
            "When an intermediate or root certificate has a name constraint which doesn't permit a DNS or other name in the leaf certificate."
        },
        INCOMPATIBLE_USAGE: {
          TITLE: 'Incompatible usage',
          DESCRIPTION: "When the certificate's key usage indicates that it may only be used for a different purpose."
        },
        NAME_CONSTRAINTS_WITHOUT_SANS: {
          TITLE: 'Name constraints without SANS',
          DESCRIPTION:
            'When a leaf certificate does not contain a Subject Alternative Name extension, but a CA certificate contains name constraints, and the Common Name can be interpreted as a hostname.'
        },
        NAME_MISMATCH: {
          TITLE: 'Name mismatch',
          DESCRIPTION: 'When the subject name of a parent certificate does not match the issuer name in the child.'
        },
        NOT_AUTHORIZED_TO_SIGN: {
          TITLE: 'Not authorized to sign',
          DESCRIPTION: "When a certificate is signed by another which isn't marked as a CA."
        },
        TOO_MANY_CONSTRAINTS: {
          TITLE: 'Too many constraints',
          DESCRIPTION: 'When the number of comparison operations needed to check a certificate is excessive.'
        },
        TOO_MANY_INTERMEDIATES: {
          TITLE: 'Too many intermediates',
          DESCRIPTION: 'When a path length constraint is violated.'
        },
        UNCONSTRAINED_NAME: {
          TITLE: 'Unconstrained name',
          DESCRIPTION:
            'When a CA certificate contains permitted name constraints, but leaf certificate contains a name of an unsupported or unconstrained type.'
        },
        CERTIFICATE_INVALID: {
          TITLE: 'Certificate invalid',
          DESCRIPTION: 'When the invalidity type is not supported by our engine, but has still be determined to be invalid.'
        },
        REVOKED_UNSPECIFIED: {
          TITLE: 'Revoked: Unspecified',
          DESCRIPTION: 'A CRL or OCSP request identified the certificate as revoked but did not provide a reason.'
        },
        REVOKED_KEY_COMPROMISED: {
          TITLE: 'Revoked: Key Compromised',
          DESCRIPTION: 'A CRL or OCSP request identified the private key associated has been compromised, making the certificate insecure.'
        },
        REVOKED_CA_COMPROMISED: {
          TITLE: 'Revoked: CA Compromised',
          DESCRIPTION: 'A CRL or OCSP request indicated that the certificate authority was somehow compromised.'
        },
        REVOKED_AFFILIATION_CHANGED: {
          TITLE: 'Revoked: Affiliation Changed',
          DESCRIPTION: 'A CRL or OCSP request indicated that the certificate no longer is associated with that CA.'
        },
        REVOKED_SUPERSEDED: {
          TITLE: 'Revoked: Superseded',
          DESCRIPTION: 'A replacement certificate has been issued for reasons unrelated to expiration, compromise, or revocation.'
        },
        REVOKED_CESSATION_OF_OPERATION: {
          TITLE: 'Revoked: Cessation of Operation',
          DESCRIPTION: 'The CA has been decommissioned and is no longer used.'
        },
        REVOKED_CERTIFICATE_HOLD: {
          TITLE: 'Revoked: Certificate Hold',
          DESCRIPTION: 'The CA indicates that it will not vouch for this certificate. A more precise reason may be provided by the CA in the future.'
        },
        REVOKED_REMOVE_FROM_CRL: {
          TITLE: 'Revoked: Remove from CRL',
          DESCRIPTION:
            'If a certificate is unrevoked after a Certificate Hold reason is issued, it will still be listed in the CRL. This reason indicates that the certificate should be removed from the CRL.'
        },
        REVOKED_PRIVILEGE_WITHDRAWN: {
          TITLE: 'Revoked: Privilege Withdrawn',
          DESCRIPTION: 'The certificate was revoked because a privilege contained within the certificate has been withdrawn.'
        },
        REVOKED_AA_COMPROMISE: {
          TITLE: 'Revoked: AA Compromise',
          DESCRIPTION: 'The AA validated in the attribute certificate is known or suspected to have been compromised.'
        },
        REVOKED_UNKNOWN_REASON_VALUE: {
          TITLE: 'Revoked: Unknown Reason Value',
          DESCRIPTION: 'The certificate has been revoked but does not have a reason conforming to RFC 5280.'
        },
        FAILED_TO_RETRIEVE_CRL: {
          TITLE: 'Failed To Retrieve CRL',
          DESCRIPTION: 'We cannot reach the CRL distribution point. We either received a 4xx or a 5xx error when fetching the CRL.'
        }
      },
      NO_ISSUES: 'No issues',
      ROW_EXPANSION: {
        FINGERPRINT: 'Fingerprint',
        FINGERPRINT_SECTION: {
          COPIED: 'copied!',
          SHA1_THUMBPRINT: 'SHA-1',
          SHA1_THUMBPRINT_TOOLTIP: 'Copy SHA-1 information.',
          SHA256_THUMBPRINT: 'SHA-256',
          SHA256_THUMBPRINT_TOOLTIP: 'Copy SHA-256 information.'
        },
        GENERAL_INFORMATION: 'General Information',
        GENERAL_INFORMATION_SECTION: {
          SUBJECT_RDN: 'Subject DN',
          ISSUER_RDN: 'Issuer DN',
          SERIAL: 'Serial',
          VALIDITY: 'Validity',
          PUBLIC_KEY: 'Public Key',
          SELF_SIGNED: 'Self Signed',
          IS_CA: 'Is Cert. Auth.'
        },
        SIGNATURE: 'Signature',
        SIGNATURE_SECTION: {
          ALGORITHM: 'Algorithm',
          SIGNATURE: 'Signature'
        }
      },
      ROWS: {
        FILE: 'File Path',
        STATUS: 'Status',
        SHA1_THUMBPRINT: 'SHA1 Fingerprint',
        SHA256_THUMBPRINT: 'SHA256 Fingerprint',
        CORRELATIONS: 'Correlations',
        EFFECTIVE_PERMISSIONS: 'Effective Permissions',
        ACTIONS: 'Actions'
      },
      TITLE: 'Detected Certificates',
      VALID_CERTIFICATE: 'Valid Certificate'
    },
    FILTER_VALUES: {
      ALL: 'All',
      TRUE: 'True',
      FALSE: 'False',
      VALID: 'Valid',
      INVALID: 'Invalid',
      ON: 'On',
      OFF: 'Off'
    },
    PRIVATE_KEY_TABLE: {
      ANALYTIC: 'PRIVATE_KEY_TABLE',
      ACTION_TOOLTIPS: {
        DOWNLOAD_PRIVATE_KEY: 'Download Private Key file'
      },
      FILTERS: {
        FIELDS: {
          filePath: 'FILEPATH',
          algorithm: 'ALGORITHM',
          foundPublicKey: 'FOUNDPUBLICKEY',
          matchHash: 'MATCHHASH',
          effectivePermissions: 'EFFECTIVEPERMISSIONS',
          correlations: 'CORRELATIONS'
        },
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        }
      },
      ROWS: {
        ACTIONS: 'Actions',
        ALGORITHM: 'Algorithm',
        FILE: 'File',
        FOUND_PRIVATE_KEY: 'Found Private Key',
        FOUND_PUBLIC_KEY: 'Found Public Key',
        CORRELATIONS: 'Correlations',
        EFFECTIVE_PERMISSIONS: 'Effective Permissions',
        MATCH_HASH: 'Match Hash',
        UNIQUE_HASH: 'Unique Hash'
      },
      TITLE: 'Detected Private Keys',
      CORRELATIONS_TOOLTIP_A: 'This private key is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.'
    },
    PUBLIC_KEY_TABLE: {
      ANALYTIC: 'PUBLIC_KEY_TABLE',
      ACTION_TOOLTIPS: {
        DOWNLOAD_PUBLIC_KEY: 'Download Public Key file'
      },
      ROWS: {
        ACTIONS: 'Actions',
        ALGORITHM: 'Algorithm',
        FILE: 'File',
        FOUND_PRIVATE_KEY: 'Found Private Key',
        FOUND_PUBLIC_KEY: 'Found Public Key',
        CORRELATIONS: 'Correlations',
        EFFECTIVE_PERMISSIONS: 'Effective Permissions',
        MATCH_HASH: 'Match Hash',
        UNIQUE_HASH: 'Unique Hash'
      },
      FILTERS: {
        FIELDS: {
          filePath: 'FILEPATH',
          algorithm: 'ALGORITHM',
          foundPrivateKey: 'FOUNDPRIVATEKEY',
          matchHash: 'MATCHHASH',
          effectivePermissions: 'EFFECTIVEPERMISSIONS',
          correlations: 'CORRELATIONS'
        },
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        }
      },
      TITLE: 'Detected Public Keys',
      CORRELATIONS_TOOLTIP_A: 'This public key is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.'
    },
    CREDENTIALS_TABLE: {
      CORRELATIONS_TOOLTIP_A: 'This user entry is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.',
      ROWS: {
        USER_NAME: 'Username',
        PASSWORD: 'Password',
        USER_INFO: 'User Info',
        HOME: 'Home',
        SHELL: 'Shell',
        CORRELATIONS: 'Correlations',
        FILE: 'File Path',
        EFFECTIVE_PERMISSIONS: 'Effective Permissions'
      },
      TITLE: 'Credentials'
    },
    HASHES_TABLE: {
      TITLE: 'Detected Hashes',
      CORRELATIONS_TOOLTIP_A: 'This hash entry is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.',
      ROWS: {
        HASH: 'Hash',
        TYPE: 'Type',
        CRACKED: 'Cracked',
        ROUNDS: 'Rounds',
        HASHCAT_MODE: 'Hashcat Mode',
        CORRELATIONS: 'Correlations',
        FILE_PATH: 'File Path'
      },
      FILTERS: {
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        },
        FIELDS: {
          hash: 'HASH',
          hashType: 'HASHTYPE',
          cracked: 'CRACKED',
          numRounds: 'NUMROUNDS',
          hashcatNumber: 'HASHCATNUMBER',
          correlations: 'CORRELATIONS',
          filePath: 'FILEPATH'
        }
      }
    },
    LINUXAUTH_TABLE: {
      CORRELATIONS_TOOLTIP_A: 'This user entry is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.',
      CRACKED_TOOLTIP: 'The plaintext value of this password has been recovered.',
      NOT_CRACKED_TOOLTIP: 'The plaintext value of this password has not yet been recovered.',
      ROWS: {
        USER_NAME: 'Username',
        HASH: 'Hash',
        HASH_TYPE: 'Hash type',
        CRACKED: 'Cracked',
        USER_INFO: 'User Info',
        HOME: 'Home',
        SHELL: 'Shell',
        FILE_PATH: 'File Path',
        CORRELATIONS: 'Correlations'
      },
      FILTERS: {
        FIELDS: {
          userName: 'USERNAME',
          passwordHash: 'PASSWORDHASH',
          hashType: 'HASHTYPE',
          cracked: 'CRACKED',
          userInfo: 'USERINFO',
          homeDir: 'HOMEDIR',
          shell: 'SHELL',
          filePath: 'FILEPATH',
          correlations: 'CORRELATIONS'
        }
      },
      TITLE: 'Linux Auth'
    },
    CORRELATIONS: {
      TOOLTIP_A: 'This file is found in ',
      TOOLTIP_B: ' other assets.' //Add => Click to view them. <= if needed in the future.
    },
    COPY_TO_CLIPBOARD_BANNER: {
      ITEM_SELECTED: 'Item selected',
      ITEMS_SELECTED: 'Items selected',
      TOOLTIP_COPY: 'Copy to CSV',
      ADD_TO_GROUPS: 'Add to Groups',
      REMOVE_FROM_GROUPS: 'Remove from Groups',
      TOOLTIP_UNSELECT: 'Remove selection',
      TOOLTIP_REMEDIATE: 'Remediate selection',
      TOOLTIP_DOWNLOAD: 'Download selection'
    },
    EFFECTIVE_PERMISSIONS: {
      TOOLTIP_777: 'This file is effectively world-readable and writable'
    },
    ASSETS: {
      ROWS: {
        ACTIONS: 'Actions',
        ASSET_TYPE: 'Type',
        ANALYSIS_STATUS: 'Analysis Status',
        NAME: 'Name',
        ARCHITECTURE: 'Architecture',
        MODEL: 'Product',
        VERSION: 'Version',
        GROUPS: 'Groups',
        KERNEL_VERSION: 'Kernel Version',
        OS: 'OS',
        BITNESS: 'Bitness',
        VENDOR: 'Vendor',
        PRODUCT: 'Product',
        NO_DATA: 'Upload a Firmware Image',
        UPDATED_AT: 'Last Modified',
        ANALYSIS_UPDATED_ON: 'Analysis Updated On',
        CREATED_AT: 'Uploaded On',
        OVERALL_RISK: 'Overall Risk',
        SHA256: 'Sha256'
      },
      TITLE: 'Assets',
      GO_TO_REPORT_TOOLTIP: 'Go to Report',
      STATUS: {
        COMPLETED: 'SUCCESS',
        FAILED: 'FAIL',
        IN_PROGRESS: 'INPROGRESS'
      },
      FIELDS: {
        NAME: 'name',
        VERSION: 'version',
        CREATEDAT: 'createdAt',
        UPDATEDAT: 'updatedAt',
        ENDTIME: 'endTime',
        PROCESSINGSTATUS: 'processingStatus',
        TYPE: 'type',
        PRODUCT: 'product',
        VENDOR: 'vendor',
        OVERALLRISK: 'riskScore',
        ASSETGROUPCOUNT: 'AssetGroupCount'
      },
      FILTERS: {
        FIELDS: {
          name: 'NAME',
          version: 'VERSION',
          MODEL: 'MODEL',
          createdAt: 'CREATEDAT',
          UPDATEDAT: 'UPDATEDAT',
          endTime: 'ENDTIME',
          vendor: 'VENDOR',
          product: 'PRODUCT',
          processingStatus: 'PROCESSINGSTATUS',
          type: 'TYPE',
          assetGroupCount: 'ASSETGROUPCOUNT',
          sha256: 'SHA256',
          overallRisk: 'RISKSCORE'
        },
        ONLY_FAILED: 'Hide failed reports',
        RISK: {
          SEVERE: 'Severe',
          SIGNIFICANT: 'Significant',
          MODERATE: 'Moderate',
          CONSERVATIVE: 'Conservative',
          NEGLIGIBLE: 'Negligible'
        },
        ANALYSIS_FILTERS: [
          { title: 'Complete', value: 'completed' },
          { title: 'In Progress', value: 'started' },
          { title: 'Failed', value: 'failed' }
        ],
        ASSET_TYPE_FILTERS: [
          { title: 'Firmware', value: 'firmware' },
          { title: 'SBOM', value: 'sbom' }
        ],
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        }
      },
      TYPES: {
        UNSPECIFIED: 'UNSPECIFIED',
        FIRMWARE: 'FIRMWARE',
        CYCLONE_DX_JSON: 'CYCLONE_DX_JSON',
        CYCLONE_DX_XML: 'CYCLONE_DX_XML',
        SPDX_JSON: 'SPDX_JSON',
        SPDX_TAG: 'SPDX_TAG',
        SBOM: 'SBOM'
      }
    },
    REPORTS_TABLE: {
      JOB_GUID_TOOLTIP: 'Go to summary',
      ROWS: {
        FIRMWARE_NAME: 'Firmware Name',
        FIRMWARE_VERSION: 'Firmware Version',
        JOB_GUID: 'Job GUID',
        JOB_NOTES: 'Job Notes',
        TIMESTAMP: 'Timestamp'
      },
      TITLE: 'Report History'
    },
    SBOM_TABLE: {
      CORRELATIONS_TOOLTIP_A: 'This component is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.',
      ERRORS: {
        DOWNLOAD_SBOM: 'Error trying to download SBOM, please try again.'
      },
      VERIFICATION_FILTER_VALUES: [
        {
          value: 'heuristic',
          title: 'Heuristic'
        },
        {
          value: 'cryptographic',
          title: 'Cryptographic Hash'
        },
        {
          value: 'package',
          title: 'Package Manager'
        },
        {
          value: 'functionHashing',
          title: 'Function Hashing'
        },
        {
          value: 'symIndex',
          title: 'Symbol Index'
        }
      ],
      SBOM_INNER_TABLE: {
        ROWS: {
          COMPONENT: 'Component',
          CVES: 'CVEs',
          LICENSE: 'License',
          VERSION: 'Version',
          CORRELATIONS: 'Correlations'
        }
      },
      ROWS: {
        CVES: 'Vulnerabilities',
        ORGANIZATION: 'Organization',
        NAME: 'Name',
        VERSION: 'Version',
        VENDOR: 'Vendor',
        VENDORS: 'Vendors',
        TYPE: 'Type',
        PACKAGE_MANAGER: 'Package Manager',
        LICENSE: 'License',
        VERIFIED_VIA: 'Verified Via',
        LICENSES: 'Licenses',
        EXPLOITS: 'Exploits',
        COMPONENTS: 'Components',
        CORRELATIONS: 'Correlations'
      },
      TITLE: 'Component Listing',
      FILTERS: {
        VALUES: [
          {
            value: HAS_EXPLOITS,
            name: 'Has exploits'
          },
          {
            value: PACKAGE_MATCH,
            name: 'Package match'
          }
        ],
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        },
        FIELDS: {
          name: 'NAME',
          vendor: 'VENDOR',
          vendors: 'VENDORS',
          type: 'TYPE',
          subtype: 'SUBTYPE',
          license: 'LICENSE',
          licenses: 'LICENSES',
          version: 'VERSION',
          vulnerability: 'VULNERABILITY',
          exploit: 'EXPLOIT',
          correlations: 'CORRELATIONS',
          components: 'COMPONENTS',
          verification: 'VERIFICATION'
        }
      },
      FILTER_MAP: {
        kernelModule: 'KERNEL_MODULE',
        os: 'OPERATING_SYSTEM'
      },
      FILTER_MAP_TITLES: {
        kernelModule: 'Kernel Module',
        os: 'Operating System'
      },
      SBOM_DETAIL_DRAWER: {
        NAME: 'Name',
        DESCRIPTION: 'Description',
        IDENTIFIERS: 'Identifiers',
        DETAIL: 'Detail',
        VENDOR: 'Vendor',
        VERSION: 'Version',
        LOADING: 'Loading...',
        TYPE: 'Type',
        PUBLISHED: 'Published',
        LICENSE: 'License',
        VERIFIED_VIA: 'Verified Via',
        SUGGESTED_REMEDIATION: 'Suggested Remediation',
        KNOWN_EXPLOITS: 'Known Exploits',
        TABS: {
          DESCRIPTION: 'DESCRIPTION',
          VULNERABILITIES: 'VULNERABILITIES',
          REFERENCE_LINKS: 'REFERENCE LINKS',
          ASSOCIATED_FILES: 'ASSOCIATED FILES',
          DEPENDENCIES: 'DEPENDENCIES',
          DEPENDENTS: 'DEPENDENTS'
        },
        ALERT: {
          MESSAGE: 'This component has ',
          KNOWN_EXPLOITS: 'Known Exploits'
        },
        ALIAS_TYPES: {
          CPE_23: 'CPE_23',
          PURL: 'PURL'
        },
        BADGES: {
          FOUNDONDISK: {
            LABEL: 'On Disk',
            TOOLTIP: `On Disk\nFiles associated with this component were found on the same disk. This acts as an indicator to confirm that the dependency is already resolved and is not something that happens at runtime.`
          },
          EMBEDDED: {
            LABEL: 'Embedded',
            TOOLTIP: `Embedded\nEvidence of this component was found but no exact file matches were located. This means the component is likely embedded within another on the same disk.`
          }
        }
      },
      TOOLTIPS: {
        BOTNET: 'Vulnerabilities used by Botnets',
        EXPLOIT: 'Exploit Code available',
        RANSOMWARE: 'Vulnerabilities used by Ransomware Groups',
        THREAT_ACTOR: 'Vulnerabilities used by Threat Actors',
        KNOWN_ATTACKS: 'Well-known or named vulnerabilities',
        KEV: 'Known Exploited Vulnerabilities'
      }
    },
    MISCONFIGURATION_TABLE: {
      ANALYTIC: 'MISCONFIGURATION_CHECKS_TABLE',
      ANALYTIC_INDEX: 'ISSUE',
      ROWS: {
        NAME: 'Name',
        CATEGORY: 'Category',
        SEVERITY: 'Severity',
        STATUS: 'Status',
        CORRELATIONS: 'Correlations'
      },
      FILTERS: {
        FIELDS: {
          displayName: 'DISPLAYNAME',
          category: 'CATEGORY',
          severity: 'SEVERITY',
          result: 'STATUS',
          correlations: 'CORRELATIONS'
        }
      },
      TITLE: 'Misconfiguration Checks',
      CORRELATIONS_TOOLTIP_A: 'This misconfiguration is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.',
      MISCONFIGURATION_DETAIL_DRAWER: {
        DESCRIPTION: 'Description',
        DETAIL: 'Detail',
        FILE: 'File Path',
        ID: 'Id',
        LOADING: 'Loading...',
        NAME: 'Name',
        SEVERITY: 'Severity',
        SUGGESTED_REMEDIATION: 'Suggested Remediation'
      }
    },
    VULNERABILITIES_TABLE: {
      CORRELATIONS_TOOLTIP_A: 'This vulnerability is found in ',
      CORRELATIONS_TOOLTIP_B: ' other assets.',
      DETAIL_TITLE_TOOLTIP: 'Go to definition',
      ROW_EXPANSION: {
        DESCRIPTION: 'Description',
        CVE_SOURCE: 'CVE Source',
        SEVERITY_AND_METRICS: {
          CVSS_V2: 'CVSS v2.0',
          CVSS_V3: 'CVSS v3.x',
          KEYS: {
            ['AttackComplexity']: 'Attack Complexity (AC)',
            ['AttackVector']: 'Attack Vector (AV)',
            ['AvailabilityImpact']: 'Availability (A)',
            ['ConfidentialityImpact']: 'Confidentiality (C)',
            ['IntegrityImpact']: 'Integrity (I)',
            ['PrivilegesRequired']: 'Privileges Required (PR)',
            ['Scope']: 'Scope (S)',
            ['UserInteraction']: 'User Interaction (UI)',
            ['VectorString']: 'Vector'
          },
          TITLE: 'Severity and Metrics',
          TOOLTIP: ' data is unavailable for this CVE.'
        }
      },
      VULNERABILITY_DETAIL_DRAWER: {
        DETAIL: 'Detail',
        TABS: {
          DESCRIPTION: 'Description',
          REFERENCES: 'References',
          CVSS_METRICS: 'CVSS Metrics',
          KNOWN_EXPLOITS: 'Known Exploits'
        },
        ALERT: {
          MESSAGE: 'This vulnerability has '
        },
        DESCRIPTION_SECTION: {
          CVE_SOURCE: 'CVE Source',
          DATE: 'Date',
          LINK_TOOLTIP: 'Go to definition',
          DESCRIPTION: 'Description',
          MODIFIED_DATE: 'Modified Date',
          PUBLISHED_DATE: 'Published Date'
        },
        REFERENCES_SECTION: {
          TITLE: 'References to Advisories, Solutions, and Tools'
        },
        SEVERITY_AND_METRICS_SECTION: {
          CVSS_V2: 'CVSS v2.0',
          CVSS_V3: 'CVSS v3.x',
          EPSS_SCORE: 'EPSS Score',
          EPSS_PERCENTILE: 'EPSS Percentile',
          EPSS_TOOLTIP_INFO:
            'The Exploit Prediction Score System (EPSS) is an open, data-driven effort that uses current threat information from CVE and real-world exploit data. The EPSS model produces a probability score between 0 and 1 (0 and 100%), where the higher the score, the greater the probability that a vulnerability will be exploited.',
          CWE: 'CWE',
          CWE_TOOLTIP_INFO:
            'Common Weakness Enumeration (CWE) is a community-developed list of software and hardware weakness types. It serves as a common language, a measuring stick for security tools, and as a baseline for weakness identification, mitigation, and prevention efforts.',
          KEYS: {
            ['AttackComplexity']: 'Attack Complexity (AC)',
            ['AttackVector']: 'Attack Vector (AV)',
            ['AvailabilityImpact']: 'Availability (A)',
            ['ConfidentialityImpact']: 'Confidentiality (C)',
            ['IntegrityImpact']: 'Integrity (I)',
            ['PrivilegesRequired']: 'Privileges Required (PR)',
            ['Scope']: 'Scope (S)',
            ['UserInteraction']: 'User Interaction (UI)',
            ['VectorString']: 'Vector'
          },
          TOOLTIP_KEYS: {
            ['attackVector']: 'Attack Vector (AV)',
            ['attackComplexity']: 'Attack Complexity (AC)',
            ['privilegesRequired']: 'Privileges Required (PR)',
            ['userInteraction']: 'User Interaction (UI)',
            ['scope']: 'Scope (S)',
            ['accessVector']: 'Access Vector (AV)',
            ['accessComplexity']: 'Access Complexity (AC)',
            ['authentication']: 'Authentication (AU)',
            ['confidentialityImpact']: 'Confidentiality (C)',
            ['integrityImpact']: 'Integrity (I)',
            ['availabilityImpact']: 'Availability (A)'
          },
          TITLE: 'Severity and Metrics',
          TOOLTIP: ' data is unavailable for this CVE.',
          VECTOR: 'Vector'
        },
        KNOWN_EXPLOITS_SECTION: {
          TITLE: 'Known Exploits',
          TOOLTIP:
            'This section identifies malicious actors who are known to exploit this vulnerability. This data is not evidence of these actors targeting your organization directly.',
          THREAT_ACTORS: 'Threat Actors',
          ATTACKS: 'Attacks',
          BOTNETS: 'Botnets',
          NAME: 'Name',
          DATE_ADDED: 'Date Added',
          REFERENCE: 'Reference',
          EXPLOIT_CODE: 'Exploit code',
          ALIASES: 'Aliases',
          RANSOMWARE: 'Ransomware',
          FAMILIES: 'Families',
          FIRST_REPORTED: 'First Reported',
          MOST_RECENT_REPORTED: 'Most Recently Reported',
          CISA_KEV: 'CISA KEV'
        }
      },
      ROWS: {
        CVE_ID: 'CVE ID',
        COMPLEXITY: 'Complexity',
        COMPONENT: 'Component',
        CORRELATIONS: 'Correlations',
        EXPLOIT_MATURITY: 'Exploit Maturity',
        IN_IMAGES: 'In Assets',
        FILE: 'File Path',
        ORGANIZATION: 'Organization',
        SEVERITY: 'Severity',
        VECTOR: 'Vector',
        VENDOR: 'Vendor',
        VERSION: 'Version',
        OCCURRENCES: 'Occurrences',
        THREAT_ACTOR: 'Threat Actors',
        EPSS_SCORE: 'EPSS',
        EPSS_SCORE_: 'EPSS Score',
        EPSS_PERCENTILE: 'EPSS Percentile',
        CVSS_SCORE: 'CVSS'
      },
      FILTERS: {
        ORDER: {
          ASC: 'ASC',
          DESC: 'DESC'
        },
        FIELDS: {
          cve: 'CVE',
          name: 'NAME',
          version: 'VERSION',
          vendor: 'VENDOR',
          maturity: 'MATURITY',
          filePath: 'FILEPATH',
          severity: 'SEVERITY',
          attackVector: 'ATTACKVECTOR',
          attackComplexity: 'ATTACKCOMPLEXITY',
          vulnerabilityRemediationStatus: 'VULNERABILITYREMEDIATIONSTATUS',
          correlations: 'CORRELATIONS',
          epssScore: 'EPSSSCORE',
          epssPercentile: 'EPSSPERCENTILE'
        },
        VULNERABILITY_REMEDIATION_STATUS_FILTERS: [
          { title: 'Exploitable', value: 'AFFECTED' },
          { title: 'False Positive', value: 'FALSE_POSITIVE' },
          { title: 'In Triage', value: 'UNDER_INVESTIGATION' },
          { title: 'Not Affected', value: 'NOT_AFFECTED' },
          { title: 'Resolved', value: 'FIXED' },
          { title: 'Resolved with Pedigree', value: 'RESOLVED_WITH_PEDIGREE' },
          { title: 'Unreviewed', value: 'UNSPECIFIED' }
        ]
      },
      TITLE: 'Detected Vulnerabilities',
      V3: 'impact',
      V2: 'impactV2',
      PROOF_OF_CONCEPT: {
        COLUMN_NAME: 'maturity',
        KEY: 'poc',
        VALUE: 'Proof of Concept'
      },
      FILEPATH_TOOLTIP: 'Go to file location',
      MATURITY_MAP: {
        POC: 'Proof of Concept'
      }
    },
    FILTER: 'Filter...',
    CLEAR_FILTER_TOOLTIP: 'clear',
    ACTIONS: 'Actions'
  },
  TAG: 'Tag',
  TAG_CREATE: {
    VALUE_LABEL: 'Value',
    VALUE_HELPER_TEXT: 'The name of the tag.',
    DESCRIPTION_LABEL: 'Description',
    DESCRIPTION_HELPER_TEXT: 'The description of the tag.',
    CREATED_AT_LABEL: 'Created',
    CREATED_AT_HELPER_TEXT: 'The date the tag was created.',
    UPDATED_AT_LABEL: 'Updated',
    UPDATED_AT_HELPER_TEXT: 'The date the tag was updated.'
  },
  TASKS: {
    TYPE: {
      UPLOAD: 'UPLOAD',
      DOWNLOAD: 'DOWNLOAD'
    }
  },
  TRUE: 'True',
  TRANSACTION_ASSET: {
    ARCHITECTURE_LABEL: 'Architecture',
    ARCHITECTURE_HELPER_TEXT: 'The CPU architecture the asset was designed to run on.',
    BITNESS_LABEL: 'Bitness',
    BITNESS_HELPER_TEXT: 'Select the supported processor bit width for the asset.',
    FIRMWARE_HELPER_TEXT: 'Browse your local folders and select the asset file.',
    FIRMWARE_LABEL: 'Select asset file.',
    FIRMWARE_REQUIRED: 'Asset file is required.',
    FIRMWARE_MAX_SIZE_MESSAGE: 'The maximum supported file size is 500GB.',
    JOB_DEFINITION_DETAILS_LABEL: 'The Job definition description',
    JOB_DEFINITION_HELPER_TEXT: 'The job definition to analyze the image with.',
    JOB_DEFINITION_LABEL: 'Job Definition',
    JOB_NAME: 'Job Name',
    JOB_NAME_HELPER_TEXT: 'The name of the Job.',
    MANUFACTURER_LABEL: 'Vendor',
    MANUFACTURER_HELPER_TEXT: 'The manufacturer of the asset.',
    MAX_FILE_SIZE_IN_BYTES: 536870912000, // 500GiB
    MODEL_LABEL: 'Product',
    MODEL_HELPER_TEXT: 'The model type of the asset.',
    NAME_HELPER_TEXT: '*Required',
    NAME_LABEL: 'Name',
    OS_LABEL: 'Operating System',
    OS_HELPER_TEXT: 'The operating system of the asset.',
    PROCESS_IMAGE_LABEL: 'Process image after upload?',
    PROCESS_IMAGE_TABLE_KEY: 'Process Image After Upload',
    VERSION_LABEL: 'Version',
    ASSET_CPE_LABEL: 'Asset CPE',
    VERSION_HELPER_TEXT: 'The version of the asset in semantic versioning form.',
    LOCAL_STATUS: {
      CREATED: 'created',
      IN_PROGRESS: 'inprogress',
      SUCCESS: 'success',
      ERROR: 'error',
      ABORTED: 'aborted',
      PROCESSING: 'processing'
    },
    CONFIRMATION_MODAL: {
      TITLE_S: 'Cancel Transfer',
      MESSAGE_S: 'Your transfer is not finished, would you like to cancel the transfer?',
      CONTINUE_S: 'No, Continue',
      CANCEL_S: 'Yes, Cancel',
      TITLE_P: 'Cancel All Transfers',
      MESSAGE_P: 'Your transfers are not finished, would you like to cancel all transfers?',
      CONTINUE_P: 'No, Continue',
      CANCEL_P: 'Yes, Cancel'
    },
    UPLOAD_SUCCESS_MESSAGE: 'uploaded successfully, analysis in progress.',
    UPLOAD_ERROR_MESSAGE: 'upload failed, try again or contact support.',
    STEPPER: {
      STEP_NAME_0: 'Identify your firmware image.',
      STEP_NAME_1: 'Add information about your firmware.',
      STEP_NAME_2: 'Choose the proper architecture and OS for the firmware image.',
      STEP_NAME_3: 'Choose image processing options.',
      STEP_NAME_4: 'Review'
    },
    SUBHEADER: 'Add a new Firmware Image to the platform.',
    TITLE: 'Upload Firmware Image'
  },
  USER: {
    INVITE: {
      ERROR_ROLE: 'You must specify a role'
    }
  },
  USER_ACTIONS: {
    DELETE: 'delete',
    DELETE_CONFIRMATION_MODAL: {
      CONFIRM_DELETE_LABEL: 'Yes, Delete',
      CANCEL_LABEL: 'No, Cancel',
      DELETE_CONTENT_FIRST_LINE: 'You are about to delete [USERNAME] from this organization, and any other organizations they belong to.',
      CONTENT_FIRST_LINE_USERNAME_PLACEHOLDER: '[USERNAME]',
      DELETE_CONTENT_SECOND_LINE: 'This cannot be undone, are you sure that you want to do this?',
      DELETE_USER: 'Delete User'
    },
    DELETE_SUCCESS_MESSAGE: 'User successfully deleted',
    DEMOTE: 'demote',
    DISABLE: 'DISABLE',
    DISABLE_CONFIRMATION_MODAL: {
      CONFIRM_DISABLE_LABEL: 'Yes, Disable',
      DISABLE_CONTENT_FIRST_LINE: 'You are about to disable [USERNAME] from this organization, and any other organizations they belong to.',
      DISABLE_CONTENT_SECOND_LINE: 'This will prevent them from logging in. You can re-enable the user later if you wish.',
      DISABLE_USER: 'Disable User'
    },
    ENABLE: 'ENABLE',
    INVITE: 'invite',
    PROMOTE: 'promote',
    REMOVE: 'remove',
    REMOVE_CONFIRMATION_MODAL: {
      CONFIRM_REMOVE_LABEL: 'Yes, Remove',
      REMOVE_CONTENT_FIRST_LINE: 'You are about to remove [USERNAME] from this organization.',
      REMOVE_CONTENT_SECOND_LINE: 'This cannot be undone, are you sure that you want to do this?',
      REMOVE_USER: 'Remove User'
    },
    REMOVE_SUCCESS_MESSAGE: 'User successfully removed',
    DISABLE_SUCCESS_MESSAGE: 'User successfully disabled',
    ENABLE_SUCCESS_MESSAGE: 'User successfully enabled',
    RESET_PASSWORD: 'disable_password'
  },
  USER_PROFILE: {
    GO_BACK: 'Go Back',
    APPEARANCE_SELECTOR: 'appearance',
    APPEARANCE_TITLE: 'Appearance',
    ORGANIZATION_SELECTOR: 'organization',
    ORGANIZATION_TITLE: 'Organization',
    PROFILE_SELECTOR: 'profile',
    PROFILE_TITLE: 'Profile',
    SECURITY_SELECTOR: 'security',
    SECURITY_TITLE: 'Security'
  },
  VALIDATIONS: {
    REQUIRED: '*Required',
    MAX_LENGTH_512: 'The maximum length of the description is 512 characters.'
  },
  VULNERABILITIES: {
    FILTERS: {
      EXPLOITABLE_THREATS_LABEL: 'Exploitable Threats',
      CISA_KEV_LABEL: 'On CISA KEV list'
    }
  }
};
