import { Table } from '@devexpress/dx-react-grid-material-ui';
import { alpha } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

/**
 * Table component
 */
const styles = theme => ({
  tableStriped: {
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    },
    '& tbody td': {
      padding: 0
    }
  }
});

const TableComponentBase = ({ classes, ...restProps }) => (
  <Table.Table {...restProps} className={classes.tableStriped} style={{ tableLayout: 'auto' }} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);
