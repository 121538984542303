export const ASSET_REPORT_GENERAL_URL = '/assets/';
export const ASSET_GROUP_REPORT_GENERAL_URL = '/groups/';

const assetsPathRegex =
  /\/assets\/[A-F0-9]{64}%7C[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}%7C[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}%7C[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;

const groupsPathRegex = /^\/groups\/[a-f0-9-]{36}\/[A-F0-9]{64}(?:%7C[a-f0-9-]{36}){3}$/i;

export function removeTabAndSubTabFromFilePath(location) {
  let sliceEnd = location.pathname.startsWith('/groups/') ? 4 : 3;
  return location.pathname.split('/').slice(0, sliceEnd).join('/');
}

export function simplifyUrl(location) {
  const pathname = removeTabAndSubTabFromFilePath(location);

  const [tabName, subTabName] = location.pathname.slice(pathname.length + 1).split('/');
  let simplifiedUrlSuffix = tabName ? (subTabName ? tabName + '&' + subTabName : tabName) : '';
  switch (true) {
    case assetsPathRegex.test(pathname):
      return ASSET_REPORT_GENERAL_URL + simplifiedUrlSuffix;
    case groupsPathRegex.test(pathname):
      return ASSET_GROUP_REPORT_GENERAL_URL + simplifiedUrlSuffix;
    default:
      return pathname;
  }
}
