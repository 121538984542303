import Typography from '@mui/material/Typography';
import React, { useContext, useEffect } from 'react';
import { ComponentContext } from '../../ContextWrappers/NRComponentContextWrapper';
import { CONSTANTS } from '../../constants/constants';
import Box from '@mui/material/Box';
import customTheme from '../customTheme';
import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NRShowAssetStatusStyles from './NRShowAssetStatus.styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate } from 'react-router-dom';
import NRRefreshIcon from '../../NRIcons/NRRefreshIcon';
const useStyles = makeStyles(NRShowAssetStatusStyles);

function NRShowAssetStatus() {
  const classes = useStyles();
  const location = useLocation();
  const { asset, latestAsset, isLatestAsset } = useContext(ComponentContext);
  const navigate = useNavigate();

  function replaceRunAndSnapshotId(componentId, newRunId, newSnapshotId) {
    const splittedAssetId = componentId?.split('|');
    if (!splittedAssetId?.length) return componentId;

    if (splittedAssetId[2] !== newRunId) {
      splittedAssetId[2] = newRunId;
    }
    if (splittedAssetId[3] !== newSnapshotId) {
      splittedAssetId[3] = newSnapshotId;
    }

    return splittedAssetId.join('|');
  }

  const redirectValue =
    !!asset?.componentId && !!latestAsset && !isLatestAsset && latestAsset?.status === CONSTANTS.TABLE.ASSETS.STATUS.COMPLETED
      ? replaceRunAndSnapshotId(asset?.componentId, latestAsset.runId, latestAsset.snapshotId)
      : null;

  const navigateToNewSnapshot = () => {
    navigate(`assets/${redirectValue}${location?.search ? location?.search : ''}`, { replace: true });
    window.location.reload();
  };

  return (
    <Box>
      {!!latestAsset && !!asset?.componentId && !!isLatestAsset && latestAsset?.status === CONSTANTS.TABLE.ASSETS.STATUS.COMPLETED && (
        <Box display={'flex'} alignItems={'center'}>
          <Typography ml={1} variant={'body2'} color={'unset'}>
            Report is up to date
          </Typography>
        </Box>
      )}
      {!!latestAsset && !!asset?.componentId && latestAsset?.status !== CONSTANTS.TABLE.ASSETS.STATUS.COMPLETED && (
        <Box display={'flex'} alignItems={'center'}>
          <CircularProgress color="primary" size={14} sx={{ mr: 1 }} />

          <Typography variant={'body2'} color={'unset'}>
            Report is updating...
          </Typography>
          <Tooltip title={CONSTANTS.REPORT.UPDATING_TOOLTIP}>
            <InfoOutlinedIcon className={classes.infoIcon} fontSize={'small'} />
          </Tooltip>
        </Box>
      )}
      {!!latestAsset && !!asset?.componentId && !isLatestAsset && latestAsset?.status === CONSTANTS.TABLE.ASSETS.STATUS.COMPLETED && (
        <Box display={'flex'} alignItems={'center'}>
          <NRRefreshIcon />
          <Typography ml={1} variant={'body2'} color={'unset'}>
            <span>New report data is available, </span>
            <span className={classes.link} onClick={navigateToNewSnapshot}>
              click here
            </span>
            <span> to refresh</span>
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default NRShowAssetStatus;
