import { createContext, useState } from 'react';

export const SBOMContext = createContext({
  isGroupedByChanging: null,
  setIsGroupedByChanging: () => {},
  openDownloadSBOM: null,
  setOpenDownloadSBOM: () => {}
});

function NRSBOMDataShare({ children }) {
  const [isGroupedByChanging, setIsGroupedByChanging] = useState(false);
  const [openDownloadSBOM, setOpenDownloadSBOM] = useState(false);
  const defaultValues = {
    isGroupedByChanging,
    setIsGroupedByChanging,
    openDownloadSBOM,
    setOpenDownloadSBOM
  };
  return <SBOMContext.Provider value={defaultValues}>{children}</SBOMContext.Provider>;
}

export default NRSBOMDataShare;
