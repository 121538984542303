import { useEffect, useState } from 'react';
import { isEmpty } from '../utils/utils';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from '@apollo/client';
import CustomError from '../components/CustomError/CustomError';

function useNRLazyQuery({ query, options, qlObjKey, transformData, defaultValue, hideError }) {
  const [isCanceled, setIsCanceled] = useState(false);
  const [makeCall, { loading, error, response, called, startPolling, stopPolling, previousData }] = useLazyQuery(query, {
    ...options,
    notifyOnNetworkStatusChange: true,
    skip: options.skipPollAttempt && options.skipPollAttempt(),
    onCompleted
  });
  const { enqueueSnackbar } = useSnackbar();
  const [total, setTotal] = useState(0);
  const [result, setResult] = useState(null);

  const handleMakeCall = options => {
    setIsCanceled(false);
    if (defaultValue !== undefined) {
      setResult(defaultValue);
    }
    makeCall(options);
  };

  function onCompleted(data) {
    if (!isCanceled && data && data[qlObjKey]) {
      if (data[qlObjKey].items) {
        setResult(!!transformData ? transformData(data[qlObjKey].items) : data[qlObjKey].items);
      } else {
        setResult(!!transformData ? transformData(data[qlObjKey]) : data[qlObjKey]);
      }
      if (data[qlObjKey].total || data[qlObjKey].total === 0) {
        setTotal(data[qlObjKey].total);
      }
    }
  }

  const handleCancel = () => {
    setIsCanceled(true); // Mark request as canceled
  };

  useEffect(() => {
    if (error && !isEmpty(error) && !hideError) {
      enqueueSnackbar(<CustomError message={error?.message} />, { variant: 'error' });
      console.error(error);
    }
  }, [error]);

  return [result, loading, called, handleMakeCall, total, error, startPolling, stopPolling, handleCancel, isCanceled];
}

export default useNRLazyQuery;
