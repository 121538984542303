export default () => ({
  paperContainer: {
    maxHeight: '60%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  paper: {
    height: '100%',
    display: 'flex',
    padding: 24,
    flexDirection: 'column'
  }
});
