import { useEffect, useState } from 'react';
import { isEmpty } from '../utils/utils';
import { useSnackbar } from 'notistack';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import CustomError from '../components/CustomError/CustomError';

function useNRQuery({ query, options, qlObjKey, transformData, defaultValue, hideError }) {
  const [callQuery, { loading, error, data, called, fetchMore }] = useLazyQuery(query, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    ...options
  });
  const [total, setTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [result, setResult] = useState(defaultValue);

  useEffect(() => {
    try {
      callQuery(options);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (data && data[qlObjKey]) {
      if (data[qlObjKey] && data[qlObjKey].items) {
        setResult(!!transformData ? transformData(data[qlObjKey].items) : data[qlObjKey].items);
      } else {
        setResult(!!transformData ? transformData(data[qlObjKey]) : data[qlObjKey]);
      }
      if (data[qlObjKey].total || data[qlObjKey].total === 0) {
        setTotal(data[qlObjKey].total);
      }
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setResult(defaultValue);
    }
  }, [loading]);

  const handleFetchMore = queryParams => {
    if (called && fetchMore) {
      callQuery({
        variables: { ...queryParams }
      });
    }
  };

  useEffect(() => {
    if (error && !isEmpty(error) && !hideError) {
      enqueueSnackbar(<CustomError message={error?.message} />, { variant: 'error' });
      console.error(error);
    }
  }, [error]);

  return [result, loading, handleFetchMore, total, called, error];
}

export default useNRQuery;
